import React from "react";
import {
  mapping,
  NonValidationInput,
  ButtonSolid,
  FormatDate,
  CalculateDate,
} from "@aim-mf/styleguide";
import moment from "moment";
// this part i think can use AutoComplete from styleguide

let KRIPeriodCount = 6;

class KRIValueEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        props.data.value !== undefined && props.data.value.length !== 0
          ? props.data.value
              .sort((a, b) => {
                return a.period > b.period ? 1 : -1;
              })
              .slice(-KRIPeriodCount)
          : defaultValue(props.data.frequency, props.data.id),
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      let value =
        nextProps.data.value !== undefined && nextProps.data.value.length !== 0
          ? this.props.data.value
              .sort((a, b) => {
                return a.period > b.period ? 1 : -1;
              })
              .slice(-KRIPeriodCount)
          : defaultValue(nextProps.data.frequency, nextProps.data.id);
      this.setState({ value: value });
    }
  }

  render() {
    // let value = this.props.data.value.slice(-6);
    let frequency = this.props.data.frequency;
    return (
      <div style={RiskCreationFormWrapStyle}>
        <div style={RiskCreationFormStyle}>
          <div style={formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Entry values for selected KRI
            </div>
            <div style={{ height: "50px" }} />
            <ContentHeader
              frequency={
                frequency === "Annually"
                  ? "Year"
                  : frequency === "Monthly"
                  ? "Month"
                  : frequency === "Daily"
                  ? "Date"
                  : frequency === "Quarterly"
                  ? "Quarter"
                  : "Quarter"
              }
            />
            {/*{[0, 1, 2, 3, 4, 5].map((value) => {*/}
            {/*  return (*/}
            {/*    <Content*/}
            {/*      period={today}*/}
            {/*      frequency={frequency}*/}
            {/*      periodOffset={value}*/}
            {/*    />*/}
            {/*  );*/}
            {/*})}*/}
            {this.state.value
              .sort((a, b) => {
                return a.period < b.period ? 1 : -1;
              })
              .map((value, index) => {
                return (
                  <Content
                    period={value.period}
                    value={value.value}
                    frequency={frequency}
                    index={index}
                    onChange={this.onValueChange}
                  />
                );
              })}
            <ButtonSolid
              name={"Add New"}
              clickEvent={() => {
                let value = this.state.value;
                value.push({
                  value: 0,
                  period: CalculateDate(
                    value[0].period,
                    frequency === "Annually",
                    frequency === "Monthly",
                    0
                  ),
                  KRI_id: this.props.data.id,
                });
                this.setState({ value: value });
              }}
            />
            <div style={{ height: "40px" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={"79px"}
                height={"39px"}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Save"}
                color={"Primary"}
                width={"79px"}
                height={"39px"}
                clickEvent={() => {
                  this.props.submitFunction(this.state.value);
                  this.props.cancelFunction();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  onValueChange = (index, value) => {
    let kriValue = this.state.value;
    kriValue[index].value = value === "" ? value : parseInt(value);
    kriValue = kriValue.sort((a, b) => {
      return a.period < b.period ? 1 : -1;
    });
    this.setState({ value: kriValue });
  };
}
const ContentHeader = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div style={{ width: "45%" }}>
        <div style={mapping["forms/label/1-default"]}>Value</div>
      </div>
      <div style={{ width: "45%" }}>
        <div style={mapping["forms/label/1-default"]}>{props.frequency}</div>
      </div>
    </div>
  );
};

const Content = (props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "10px",
      }}
    >
      <div style={{ width: "45%" }}>
        <NonValidationInput
          fieldstyle={InputFieldStyle}
          value={props.value}
          width={"100%"}
          onChange={(event) => {
            props.onChange(props.index, event.value);
          }}
        />
      </div>
      <div style={{ width: "45%" }}>
        <div style={{ ...mapping["forms/label/1-default"], padding: 0 }}>
          {FormatDate(
            props.period,
            props.frequency === "Annually"
              ? "YYYY"
              : props.frequency === "Monthly"
              ? "YYYY-MMM"
              : props.frequency === "Daily"
              ? "YYYY-MM-DD"
              : props.frequency === "Quarterly"
              ? "YYYY-"
              : "YYYY-"
          )}
          {props.frequency === "Quarterly"
            ? "Q" + moment(props.period).quarter()
            : ""}
        </div>
      </div>
    </div>
  );
};

const InputFieldStyle = {
  height: mapping["Field_Height"],
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const formBgStyle = {
  height: "fit-content",
  width: "579px",
  padding: "23px 18px 43px 18px",
  backgroundColor: mapping["Color_Extra_Half_Transparent"],
  //display: "inline-flex",
  position: "relative",
};
const RiskCreationFormStyle = {
  position: "relative",
  left: "-50%",
};
const RiskCreationFormWrapStyle = {
  position: "absolute",
  left: "50%",
  top: "13.3rem",
};

const defaultValue = (frequency, KRI_id) => {
  let today = new Date();
  if (frequency === "Quarterly") {
    return [
      {
        value: 0,
        period: moment(today).quarter(moment(today).quarter()),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -3, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -6, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -9, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -12, 0).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(today, 0, -15, 0).toDate(),
        KRI_id: KRI_id,
      },
    ];
  } else {
    return [
      { value: 0, period: today, KRI_id: KRI_id },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -1 : 0,
          frequency === "Monthly" ? -1 : 0,
          frequency === "Daily" ? -1 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -2 : 0,
          frequency === "Monthly" ? -2 : 0,
          frequency === "Daily" ? -2 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -3 : 0,
          frequency === "Monthly" ? -3 : 0,
          frequency === "Daily" ? -3 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -4 : 0,
          frequency === "Monthly" ? -4 : 0,
          frequency === "Daily" ? -4 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
      {
        value: 0,
        period: CalculateDate(
          today,
          frequency === "Annually" ? -5 : 0,
          frequency === "Monthly" ? -5 : 0,
          frequency === "Daily" ? -5 : 0
        ).toDate(),
        KRI_id: KRI_id,
      },
    ];
  }
};

export { KRIValueEntry };
