import React from "react";
import {
  mapping,
  RiskEditCardAddNew,
  RiskEditCardHeader,
  IconSVG,
  NameCard,
  CustomizedCheckBox,
  ButtonSolid,
  NonValidationInput,
  CustomDatePicker,
  FormatDate,
  Pill,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";

import { RiskEditCardStyle } from "../risk-edit-common-style";
const RiskAPStyle = {
  paddingLeft: "5rem",
  paddingTop: "2.5rem",
};

class ActionPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={RiskAPStyle}>
        <div style={RiskEditCardStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          <div style={{ marginTop: "3rem" }}>
            {this.props.actionPlans.length !== 0 &&
              this.props.actionPlans.map((actionPlan, index) => {
                if (this.props.rights.edit === 1) {
                  return (
                    <div key={index} style={{ margin: "1.25rem" }}>
                      <ActionPlanCard
                        index={index}
                        actionPlan={actionPlan}
                        handleAPDescriptionChange={
                          this.handleAPDescriptionChange
                        }
                        handleAPOwnerChange={this.handleAPOwnerChange}
                        handleAPStatusChange={this.handleAPStatusChange}
                        handleAPTimelineChange={this.handleAPTimelineChange}
                        handleDelete={this.handleDelete}
                        handleAPCompleteChange={this.handleAPCompleteChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} style={{ margin: "1.25rem" }}>
                      <ActionPlanCardView actionPlan={actionPlan} />
                    </div>
                  );
                }
              })}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new Action Plan"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleAddNew = () => {
    let existingAPs = this.props.actionPlans;
    let existingAPsNumber = existingAPs.map((AP) => {
      return parseInt(AP.number.slice(1));
    });
    if (existingAPsNumber.length === 0) {
      existingAPsNumber.push(0);
    }
    let newAPNumber = "A" + (Math.max(...existingAPsNumber) + 1);
    let newAP = {
      id: 0,
      number: newAPNumber,
      description: "\u00A0",
      owner: [],
      status: "Automatic",
      deadline: FormatDate(new Date(), "YYYY-MM-DD"),
      is_completed: false,
    };
    existingAPs.push(newAP);
    let newAPs = existingAPs;
    this.props.handleActionPlanChange(newAPs);
  };
  handleAPDescriptionChange = (value, index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs[index].description = value;
    let newAPs = existingAPs;
    this.props.handleActionPlanChange(newAPs);
  };

  handleAPStatusChange = (event) => {
    let existingAPs = this.props.actionPlans;
    let index = event.target.props.index;
    existingAPs[index].frequency = event.value;
    let newAPs = existingAPs;
    this.props.handleActionPlanChange(newAPs);
  };
  handleAPTimelineChange = (date, index) => {
    let existingAPs = this.props.actionPlans;
    let newdate = FormatDate(date, "YYYY-MM-DD");
    existingAPs[index].deadline = newdate;
    let newAPs = existingAPs;
    this.props.handleActionPlanChange(newAPs);
  };
  handleAPCompleteChange = (index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs[index].is_completed = !existingAPs[index].is_completed;
    let newAPs = existingAPs;
    this.props.handleActionPlanChange(newAPs);
  };
  handleDelete = (index) => {
    let existingAPs = this.props.actionPlans;
    existingAPs.splice(index, 1);
    let newAPs = existingAPs;
    this.props.handleActionPlanChange(newAPs);
  };

  handleAPOwnerChange = (index) => {
    this.props.handleAddUser(
      "actionplans",
      index,
      this.props.actionPlans[index].owner,
      "Assign Action Plan Owner"
    );
  };
}

const ActionPlanCard = (props) => {
  return (
    <div style={cardStyle}>
      {/*top right tool bar*/}
      <div
        style={{
          height: "0px",
          display: "inline-flex",
          float: "right",
          margin: "0.5em",
        }}
      >
        <CustomizedCheckBox
          value={props.actionPlan.is_completed === true}
          onCheckChange={() => {
            props.handleAPCompleteChange(props.index);
          }}
        />
        <div style={CheckBoxLabelStyle}>Mark as done</div>
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleDelete(props.index);
          }}
        />{" "}
        {/*#5C7593*/}
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ActionPlanNumber actionPlanNumber={props.actionPlan.number} />
          <ActionPlanDescription
            description={props.actionPlan.description}
            handleAPDescriptionChange={props.handleAPDescriptionChange}
            index={props.index}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "70%",
            justifyContent: "space-between",
          }}
        >
          <ActionPlanOwner
            APOwners={props.actionPlan.owner}
            handleAPOwnerChange={props.handleAPOwnerChange}
            index={props.index}
          />
          <ActionPlanStatus
            handleAPStatusChange={props.handleAPStatusChange}
            disabled={true}
            status={props.actionPlan.status}
            APTimeline={props.actionPlan.deadline}
          />
          <ActionPlanTimeline
            index={props.index}
            APTimeline={props.actionPlan.deadline}
            handleAPTimelineChange={props.handleAPTimelineChange}
          />
        </div>
      </div>
    </div>
  );
};
const ActionPlanNumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>NO.</div>
      <div style={contentTextStyle}>{props.actionPlanNumber}</div>
    </div>
  );
};
const ActionPlanDescription = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextInput
          content={props.description}
          onCustomChange={props.handleAPDescriptionChange}
          index={props.index}
        />
      </div>
    </div>
  );
};
const ActionPlanOwner = (props) => {
  const OwnerChange = () => {
    props.handleAPOwnerChange(props.index);
  };
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Action Plan Owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.APOwners.length !== 0 &&
          props.APOwners.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={"18px"}
        width={"52px"}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const ActionPlanStatus = (props) => {
  let dueDays = Math.ceil(
    (new Date(props.APTimeline) - new Date()) / (1000 * 60 * 60 * 24)
  );
  return (
    <div style={{ paddingRight: "2rem", width: "11rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Status</div>
      <div style={{ paddingTop: "1.7rem", display: "inline-flex" }}>
        <Pill
          pillStyle={{ height: "1.5rem" }}
          contentStyle={{
            display: "flex",
            height: "1.5rem",
            alignItems: "center",
          }}
          content={props.status}
          color={statusColorMapping[props.status]}
        />
        {dueDays > 0 && <div style={statusDueDate}>Due in {dueDays} days</div>}
        {dueDays === 0 && <div style={statusDueDate}>Due Today</div>}
        {dueDays < 0 && (
          <div style={statusDueDate}>Overdue {-dueDays} days</div>
        )}
      </div>
    </div>
  );
};
const ActionPlanTimeline = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Timeline</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <CustomDatePicker
          index={props.index}
          CustomOnChange={props.handleAPTimelineChange}
          value={props.APTimeline}
        />
      </div>
    </div>
  );
};

const ActionPlanCardView = (props) => {
  return (
    <div style={cardStyle}>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ActionPlanNumber actionPlanNumber={props.actionPlan.number} />
          <ActionPlanDescriptionView
            description={props.actionPlan.description}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "70%",
            justifyContent: "space-between",
          }}
        >
          <ActionPlanOwnerView APOwners={props.actionPlan.owner} />
          <ActionPlanStatus
            handleAPStatusChange={props.handleAPStatusChange}
            disabled={true}
            status={props.actionPlan.status}
            APTimeline={props.actionPlan.deadline}
          />
          <ActionPlanTimelineView APTimeline={props.actionPlan.deadline} />
        </div>
      </div>
    </div>
  );
};
const ActionPlanDescriptionView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} />
      </div>
    </div>
  );
};
const ActionPlanOwnerView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Action Plan Owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.APOwners.length !== 0 &&
          props.APOwners.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const ActionPlanTimelineView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Timeline</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <CustomDatePicker value={props.APTimeline} disabled={true} />
      </div>
    </div>
  );
};

const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.index);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={InputFieldStyle}
      value={props.content}
      CustomizedOnChange={onChange}
    />
  );
};
const TextContent = (props) => {
  return (
    <div style={TextWrapperStyle}>
      <div style={TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};
const TextWrapperStyle = {
  padding: "0.25rem",
  height: "fit-content",
  width: "58.5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "1px solid rgba(151,151,151,0)",
};

const TextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  padding: "0.25rem",
};
const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "58.5rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};

const contentTextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  paddingTop: "1.7rem",
  paddingBottom: "0.5rem",
};

const cardStyle = {
  width: "65.875rem",
  height: "fit-content",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};

const CheckBoxLabelStyle = {
  ...mapping["forms/label/1-default"],
  color: "white",
  padding: "0.3rem",
  paddingLeft: "0.8rem",
  paddingRight: "1.5rem",
};
const statusDueDate = {
  ...mapping["paragraph/tiny/lightleft"],
  paddingTop: "0.4rem",
  paddingLeft: "0.5rem",
};
const statusColorMapping = {
  "On Track": mapping["Color_Platform_RiskLow"] + "66",
  Overdue: mapping["Color_Platform_RiskHigh"] + "66",
  "In Progress": mapping["Color_Platform_RiskMed"] + "66",
};
export { ActionPlans };
