import React from "react";
import { RiskEditCardStyle } from "../risk-edit-common-style";
import { IconSVG, mapping, FormatDate } from "@aim-mf/styleguide";

class RiskVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskDetail,
      createNewRisk: false,
    };
  }

  render() {
    return (
      <div style={riskVersionWrapperStyle}>
        <div style={RiskEditCardStyle.sectionTitleStyle}>VERSION</div>
        <div className={"scrollbar"} style={riskVersionContentWrapperStyle}>
          {this.props.riskDetail.versions.length !== 0 &&
            this.props.riskDetail.versions
              .sort((a, b) => {
                return new Date(a.createdDatetime) < new Date(b.createdDatetime)
                  ? 1
                  : -1;
              })
              .map((version) => {
                return (
                  <button
                    onClick={() => {
                      this.props.handleUpdateWithNewRiskDetail(version.id);
                    }}
                    style={
                      this.props.riskDetail.id === version.id ||
                      this.state.versionButtonHoverID === version.id
                        ? VersionButtonHover
                        : VersionButton
                    }
                    onMouseEnter={() => {
                      this.setState({ versionButtonHoverID: version.id });
                      // eslint-disable-next-line no-console
                      console.log(version.id);
                    }}
                    onMouseLeave={() => {
                      this.setState({ versionButtonHoverID: -1 });
                    }}
                  >
                    <div style={versionButtonContentStyle}>
                      <div
                        style={{
                          ...versionButtonLeftIconStyle,
                          backgroundColor:
                            this.props.riskDetail.id === version.id
                              ? mapping["Color_Basic_Primary"]
                              : mapping["Color_Basic_Secondary"],
                        }}
                      >
                        {version.status === "active" ? (
                          version.is_delegate || version.is_owner ? (
                            <IconSVG
                              name={"branchActive"}
                              color={"white"}
                              size={"1"}
                            />
                          ) : (
                            <IconSVG
                              name={"commitActive"}
                              color={"white"}
                              size={"1"}
                            />
                          )
                        ) : version.is_delegate || version.is_owner ? (
                          <IconSVG
                            name={"branchInactive"}
                            color={"white"}
                            size={"1"}
                          />
                        ) : (
                          <IconSVG
                            name={"commitInactive"}
                            color={"white"}
                            size={"1"}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: "131px",
                          paddingLeft: "6px",
                          fontSize: 0,
                        }}
                      >
                        <div style={versionButtonNameStyle}>
                          {version.createdUser}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: "13px",
                            marginTop: "6px",
                          }}
                        >
                          <div style={versionButtonDateStyle}>
                            {FormatDate(version.createdDatetime, "DD/MM/YYYY")}
                          </div>
                          <div style={versionButtonStatusStyle}>
                            {version.status}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginRight: "6px",
                          marginLeft: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconSVG name={"moreHori"} color={"white"} />
                      </div>
                    </div>
                  </button>
                );
              })}
        </div>
        <div style={versionDotLineStyle} />
        <div style={versionButtonViewMoreWrapperStyle}>
          <div style={versionDotCircleStyle} />
        </div>
      </div>
    );
  }
}

const versionButtonContentStyle = {
  display: "inline-flex",
  width: "fit-content",
  margin: "auto",
  height: "fit-content",
};
const versionButtonLeftIconStyle = {
  backgroundColor: mapping["Color_Basic_Secondary"],
  borderRadius: "50%",
  width: "2.375rem",
  height: "2.375rem",
};
const versionButtonNameStyle = {
  textAlign: "left",
  ...mapping["Font_Version_Name"],
  lineHeight: "17px",
};
const versionButtonDateStyle = {
  ...mapping["Font_Version_Date"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionButtonStatusStyle = {
  ...mapping["Font_Version_Status"],
  width: "50%",
  textAlign: "left",
  display: "inline-flex",
};
const versionDotLineStyle = {
  top: "4rem",
  marginLeft: "22.5px",
  height: "16rem",
  display: "inline-block",
  borderStyle: "dashed",
  borderLeftWidth: "1px",
  zIndex: "0",
  position: "absolute",
  borderColor: "rgba(181,198,209,0.5)",
};
const versionButtonViewMoreWrapperStyle = {
  marginLeft: "18px",
  fontSize: 0,
  position: "absolute",
  top: "20rem",
};
const versionDotCircleStyle = {
  height: "10px",
  width: "10px",
  backgroundColor: "283244",
  borderRadius: "50%",
  border: "solid",
  borderColor: "#6C7A88",
  display: "inline-block",
};
const riskVersionContentWrapperStyle = {
  marginTop: "20px",
  width: "16rem",
  height: "17.5rem",
  fontSize: 0,
  position: "relative",
  overflowY: "scroll",
};
const riskVersionWrapperStyle = {
  width: "fit-content",
  height: "fit-content",
  fontSize: 0,
  position: "relative",
};
const VersionButton = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  outline: "none",
};

const VersionButtonHover = {
  width: "13.5rem",
  height: "2.75rem",
  backgroundColor: "#3D4657",
  borderRadius: "20.5px",
  display: "flex",
  position: "relative",
  zIndex: 1,
  marginBottom: "0.8rem",
  border: "2px solid #00A2FF",
  outline: "none",
};
export { RiskVersion };
