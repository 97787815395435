import React from "react";
import {
  mapping,
  RiskEditCardAddNew,
  RiskEditCardHeader,
  IconSVG,
  NameCard,
  CustomizedCheckBox,
  NonValidationInput,
  DropDownListButton,
  ButtonSolid,
  Pill,
  AutoSizeTextArea,
  SearchAutoComplete,
} from "@aim-mf/styleguide";

import { RiskEditCardStyle } from "../risk-edit-common-style";

const RiskControlsStyle = {
  paddingLeft: "5rem",
  paddingTop: "2.5rem",
};

class ExistingControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={RiskControlsStyle}>
        <div style={RiskEditCardStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          <div style={{ marginTop: "3rem" }}>
            {this.props.controls.length !== 0 &&
              this.props.controls.map((control, index) => {
                if (this.props.rights.edit === 1) {
                  return (
                    <div key={index} style={{ margin: "1.25rem" }}>
                      <ControlCard
                        index={index}
                        control={control}
                        handleControlDescriptionChange={
                          this.handleControlDescriptionChange
                        }
                        handleControlOwnerChange={this.handleControlOwnerChange}
                        handleControlFrequencyChange={
                          this.handleControlFrequencyChange
                        }
                        handleControlTypeChange={this.handleControlTypeChange}
                        handleControlStrengthChange={
                          this.handleControlStrengthChange
                        }
                        handleDelete={this.handleDelete}
                        controlsList={this.props.controlsList}
                        controlSearchIndex={this.props.controlSearchIndex}
                        handleSearchGroupRisk={this.props.handleSearchControls}
                        handleriskDetailRequest={
                          this.props.handleControlDetailRequest
                        }
                        handleSourceChange={this.handleSourceChange}
                        searching={this.props.searching}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} style={{ margin: "1.25rem" }}>
                      <ControlCardView control={control} />
                    </div>
                  );
                }
              })}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new Control"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleAddNew = () => {
    let existingControls = this.props.controls;
    let existingControlsNumber = existingControls.map((control) => {
      return parseInt(control.number.slice(1));
    });

    if (existingControlsNumber.length === 0) {
      existingControlsNumber.push(0);
    }
    let newControlNumber = "C" + (Math.max(...existingControlsNumber) + 1);
    let newControl = {
      number: newControlNumber,
      description: "",
      owner: [],
      frequency: "Annually",
      control_type: "Automatic",
      strength: "Strong",
      is_ia_assurance: false,
      is_management_assurance: false,
      is_other_assurance: false,
    };
    existingControls.push(newControl);
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlDescriptionChange = (value, index) => {
    let existingControls = this.props.controls;
    existingControls[index].description = value;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlFrequencyChange = (event) => {
    let existingControls = this.props.controls;
    let index = event.target.props.index;
    existingControls[index].frequency = event.value;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlTypeChange = (event) => {
    let existingControls = this.props.controls;
    let index = event.target.props.index;
    existingControls[index].control_type = event.value;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlStrengthChange = (index, strength) => {
    let existingControls = this.props.controls;
    existingControls[index].strength = strength;
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleDelete = (index) => {
    let existingControls = this.props.controls;
    existingControls.splice(index, 1);
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleSourceChange = (index, type) => {
    let existingControls = this.props.controls;
    existingControls[index][type] = !existingControls[index][type];
    let newControls = existingControls;
    this.props.handleRiskControlChange(newControls);
  };
  handleControlOwnerChange = (index) => {
    this.props.handleAddUser(
      "controls",
      index,
      this.props.controls[index].owner,
      "Assign Control Owner"
    );
  };
}

const ControlCard = (props) => {
  return (
    <div style={cardStyle}>
      {/*top right tool bar*/}
      <div style={{ float: "right", margin: "0.5em" }}>
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleDelete(props.index);
          }}
        />
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ControlNumber controlNumber={props.control.number} />
          <ControlDescription
            controlsList={props.controlsList}
            controlSearchIndex={props.controlSearchIndex}
            description={props.control.description}
            handleControlDescriptionChange={
              props.handleControlDescriptionChange
            }
            index={props.index}
            handleSearchGroupRisk={props.handleSearchGroupRisk}
            handleriskDetailRequest={props.handleriskDetailRequest}
            searching={props.searching}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <ControlOwner
            controlOwner={props.control.owner}
            index={props.index}
            handleControlOwnerChange={props.handleControlOwnerChange}
          />
          <ControlFrequency
            handleControlFrequencyChange={props.handleControlFrequencyChange}
            frequency={props.control.frequency}
            frequencyList={["Annually", "Quarterly", "Monthly", "Daily"]}
            index={props.index}
          />
          <ControlType
            handleControlTypeChange={props.handleControlTypeChange}
            type={props.control.control_type}
            typeList={["Automatic", "Manual"]}
            index={props.index}
          />
          <ControlStrength
            strength={props.control.strength}
            index={props.index}
            handleControlStrengthChange={props.handleControlStrengthChange}
          />
          <ControlSource
            managementSource={props.control.is_management_assurance}
            iaSource={props.control.is_ia_assurance}
            otherSource={props.control.is_other_assurance}
            index={props.index}
            handleSourceChange={props.handleSourceChange}
          />
        </div>
      </div>
    </div>
  );
};
const ControlNumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>NO.</div>
      <div style={contentTextStyle}>{props.controlNumber}</div>
    </div>
  );
};
const ControlDescription = (props) => {
  const handleControlSearch = (searchContent) => {
    props.handleSearchGroupRisk(searchContent, props.index);
    props.handleControlDescriptionChange(searchContent, props.index);
  };
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>description</div>
      <div style={{ marginTop: "1.25rem" }}>
        <SearchAutoComplete
          // data ternary function is to only enable dropdown for current autocomplete
          inputFieldStyle={InputFieldStyle}
          data={
            props.controlSearchIndex === props.index
              ? props.controlsList.map(({ id, description }) => ({
                  id,
                  description,
                }))
              : []
          }
          value={props.description}
          filterKey={"description"}
          handleSearchGroupRisk={handleControlSearch}
          handleriskDetailRequest={props.handleriskDetailRequest}
          searching={props.searching}
        />
      </div>
    </div>
  );
};
const ControlOwner = (props) => {
  const OwnerChange = () => {
    props.handleControlOwnerChange(props.index);
  };
  return (
    <div style={{ paddingRight: "2rem", width: "170px" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>control owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.controlOwner.length !== 0 &&
          props.controlOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={"18px"}
        width={"52px"}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const ControlFrequency = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.frequency}
          data={props.frequencyList}
          CustomOnChange={props.handleControlFrequencyChange}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={props.disabled}
          index={props.index}
        />
      </div>
    </div>
  );
};
const ControlType = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.type}
          data={props.typeList}
          CustomOnChange={props.handleControlTypeChange}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={props.disabled}
          index={props.index}
        />
      </div>
    </div>
  );
};
const ControlStrength = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>control strength</div>
      <div style={{ paddingTop: "1.5rem", display: "inline-flex" }}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            props.handleControlStrengthChange(props.index, "Strong");
          }}
        >
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.strength === "Strong" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Strong"}
            color={
              mapping["Color_Platform_RiskLow"] +
              (props.strength === "Strong" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            props.handleControlStrengthChange(props.index, "Moderate");
          }}
        >
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.strength === "Moderate" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Moderate"}
            color={
              mapping["Color_Platform_RiskMed"] +
              (props.strength === "Moderate" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            props.handleControlStrengthChange(props.index, "Weak");
          }}
        >
          <Pill
            contentStyle={{
              color: props.strength === "Weak" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Weak"}
            color={
              mapping["Color_Platform_RiskHigh"] +
              (props.strength === "Weak" ? "66" : "3B")
            }
          />
        </div>
      </div>
    </div>
  );
};
const ControlSource = (props) => {
  const ManagementCheck = () => {
    props.handleSourceChange(props.index, "is_management_assurance");
  };
  const IACheck = () => {
    props.handleSourceChange(props.index, "is_ia_assurance");
  };
  const OtherCheck = () => {
    props.handleSourceChange(props.index, "is_other_assurance");
  };
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Source of Assurance</div>

      <ControlSourceCheckbox
        disabled={props.disabled}
        value={props.managementSource === true}
        onCheck={ManagementCheck}
        label={"Management Assurance"}
      />

      <ControlSourceCheckbox
        disabled={props.disabled}
        value={props.iaSource === true}
        onCheck={IACheck}
        label={"Internal Audit Assurance"}
      />
      <ControlSourceCheckbox
        disabled={props.disabled}
        value={props.otherSource === true}
        onCheck={OtherCheck}
        label={"Other Assurance"}
      />
    </div>
  );
};
const ControlSourceCheckbox = (props) => {
  return (
    <div>
      <div style={{ display: "inline-flex", margin: "0.5em" }}>
        <CustomizedCheckBox
          value={props.value}
          checkBoxDivStyle={{ lineHeight: 0 }}
          onCheckChange={props.onCheck}
          disabled={props.disabled}
        />
        <div style={CheckBoxLabelStyle}>{props.label}</div>
      </div>
    </div>
  );
};
const ControlCardView = (props) => {
  return (
    <div style={cardStyle}>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <ControlNumber controlNumber={props.control.number} />
          <ControlDescriptionView description={props.control.description} />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <ControlOwnerView controlOwner={props.control.owner} />
          <ControlFrequencyView
            frequency={props.control.frequency}
            frequencyList={["Annually", "Quarterly", "Monthly", "Daily"]}
          />
          <ControlTypeView
            type={props.control.control_type}
            typeList={["Automatic", "Manual"]}
          />
          <ControlStrengthView strength={props.control.strength} />

          <ControlSource
            disabled={true}
            managementSource={props.control.is_management_assurance}
            iaSource={props.control.is_ia_assurance}
            otherSource={props.control.is_other_assurance}
            index={props.index}
            handleSourceChange={props.handleSourceChange}
          />
        </div>
      </div>
    </div>
  );
};
const ControlDescriptionView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} />
      </div>
    </div>
  );
};
const ControlOwnerView = (props) => {
  return (
    <div style={{ paddingRight: "2rem", width: "170px" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>control owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.controlOwner.length !== 0 &&
          props.controlOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const ControlFrequencyView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.frequency}
          data={props.frequencyList}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const ControlTypeView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.type}
          data={props.typeList}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const ControlStrengthView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>control strength</div>
      <div style={{ paddingTop: "1.5rem", display: "inline-flex" }}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div>
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.strength === "Strong" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Strong"}
            color={
              mapping["Color_Platform_RiskLow"] +
              (props.strength === "Strong" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div>
          <Pill
            pillStyle={{ marginRight: "1rem" }}
            contentStyle={{
              color: props.strength === "Moderate" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Moderate"}
            color={
              mapping["Color_Platform_RiskMed"] +
              (props.strength === "Moderate" ? "66" : "3B")
            }
          />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div>
          <Pill
            contentStyle={{
              color: props.strength === "Weak" ? "#FFFFFFD0" : "#FFFFFF3B",
            }}
            content={"Weak"}
            color={
              mapping["Color_Platform_RiskHigh"] +
              (props.strength === "Weak" ? "66" : "3B")
            }
          />
        </div>
      </div>
    </div>
  );
};

const ControlSourceView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Source of Assurance</div>
      <ControlSourceCheckbox
        value={props.managementSource}
        label={"Management Assurance"}
        disabled={true}
      />

      <ControlSourceCheckbox
        value={props.iaSource}
        label={"Internal Audit Assurance"}
        disabled={true}
      />
      <ControlSourceCheckbox
        value={props.otherSource}
        label={"Other Assurance"}
        disabled={true}
      />
    </div>
  );
};
const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.index);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={InputFieldStyle}
      value={props.content}
      CustomizedOnChange={onChange}
    />
  );
};
const TextContent = (props) => {
  return (
    <div style={TextWrapperStyle}>
      <div style={TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};
const TextWrapperStyle = {
  padding: "0.25rem",
  height: "fit-content",
  width: "58.5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "1px solid rgba(151,151,151,0)",
};

const TextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  padding: "0.25rem",
};
const contentTextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  paddingTop: "1.7rem",
  paddingBottom: "0.5rem",
};

const cardStyle = {
  width: "65.875rem",
  height: "fit-content",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};

const riskTierDropDownButtonStyle = {
  width: "7rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "58.5rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
const CheckBoxLabelStyle = {
  ...mapping["forms/label/1-default"],
  color: "white",
  padding: "0.3rem",
  paddingLeft: "0.8rem",
  paddingRight: "1.5rem",
};
export { ExistingControl };
