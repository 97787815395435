import React from "react";
import { mapping } from "@aim-mf/styleguide";
import { Summary } from "./riskRegisterComponents/summary";
import { RiskRating } from "./riskRegisterComponents/riskRating";
import { RiskAppetite } from "./riskRegisterComponents/riskAppetite";

const RiskSummaryStyle = {
  width: "100%",
  height: "fit-content",
  backgroundColor: mapping["Color_BG_05_solid"],
};

const blockPaddingStyle = {
  paddingLeft: "5rem",
  paddingTop: "3rem",
};

class RiskSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskList,
      createNewRisk: false,
    };
  }

  render() {
    return (
      <div style={RiskSummaryStyle}>
        <Summary
          title={"Summary"}
          lastEdit={this.props.lastEdit}
          style={blockPaddingStyle}
          riskDetail={this.props.riskDetail}
          handleCreateTask={this.props.handleCreateTask}
        />
        <RiskRating
          title={"Risk Rating"}
          lastEdit={this.props.lastEdit}
          style={blockPaddingStyle}
          riskDetail={this.props.riskDetail}
          handleRiskRatingChange={this.props.handleRiskRatingChange}
          rights={this.props.rights}
          handleCreateTask={this.props.handleCreateTask}
        />
        <RiskAppetite
          title={"Risk Appetite"}
          lastEdit={this.props.lastEdit}
          style={blockPaddingStyle}
          riskAppetite={this.props.riskDetail.riskappetite}
          riskAppetiteLevel={this.props.riskDetail.risk_appetite_level}
          riskAppetiteStatement={this.props.riskDetail.risk_appetite_statement}
          handleRiskAppetiteLevelChange={
            this.props.handleRiskAppetiteLevelChange
          }
          handleRiskAppetiteStatementChange={
            this.props.handleRiskAppetiteStatementChange
          }
          handleRiskAppetiteChange={this.props.handleRiskAppetiteChange}
          handleAddUser={this.props.handleAddUser}
          rights={this.props.rights}
          handleCreateTask={this.props.handleCreateTask}
        />
        <div style={bottomPaddingStyle} />
      </div>
    );
  }
}
const bottomPaddingStyle = {
  height: "5rem",
};
export { RiskSummary };
