import React from "react";
import {
  mapping,
  Pill,
  Risk_Matrix_Component,
  RiskEditCardHeader,
  DropDownListButton,
  getRiskRating,
} from "@aim-mf/styleguide";

const RiskRatingStyle = {
  paddingLeft: "5rem",
  paddingTop: "2.5rem",
};

var riskLevel = ["Very Low", "Low", "Moderate", "High", "Very High"];
var likelihoodLevel = [
  "Very Unlikely",
  "Unlikely",
  "Moderate",
  "Likely",
  "Very Likely",
];
//  --- Left container. Not seen.
var leftBox = {
  marginLeft: "20px",
  marginTop: "20px",
  clear: "both",
  float: "left",
  height: "70px",
};

//  --- Top ribbon on left side for headers
var leftBoxHeader = {
  height: "40px",
  color: "rgb(53, 66, 92)",
};

//  --- For the three grey boxes on the left
var leftSubBox = {
  marginTop: "10px",
  width: "35.125rem",
  height: "3.8125rem",
  background: "rgb(53, 66, 92)",
  color: "rgb(53, 66, 92)",
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};

//  --- Get pill colour based on risk rating
function pillColor(rating) {
  if (rating == 3) {
    return mapping["Color_Platform_RiskHigh"] + "66";
  } else if (rating == 2) {
    return mapping["Color_Platform_RiskMed"] + "66";
  } else if (rating == 1) {
    return mapping["Color_Platform_RiskLow"] + "66";
  }
}

const ratingMaping = {
  1: "Low",
  2: "Medium",
  3: "High",
};

class RiskRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      riskList: this.props.riskList,
      createNewRisk: false,
    };
  }

  render() {
    var matrix = {
      gross: this.props.riskDetail.gross,
      residual: this.props.riskDetail.residual,
      target: this.props.riskDetail.target,
    };
    return (
      <div style={RiskRatingStyle}>
        <div style={RiskRatingContentStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />
          {/* ACTUAL CONTENT --------------------------------------------------- */}
          <body>
            <RatingDetail
              matrix={matrix}
              handleRiskRatingChange={this.handleRiskRatingChange}
              rights={this.props.rights}
            />
            {/* RIGHT SIDE CONTENT --------------------- */}
            <div style={rightBox}>
              <div style={riskRatingMatrixStyle}>
                <Risk_Matrix_Component
                  MatrixSetting={this.state.matrixSetting}
                  singleRisk={true}
                  riskRating={[
                    {
                      Gross: this.props.riskDetail.gross,
                      Residual: this.props.riskDetail.residual,
                      Target: this.props.riskDetail.target,
                    },
                  ]}
                  categoryList={["Gross", "Residual", "Target"]}
                />
              </div>
            </div>
          </body>
        </div>
      </div>
    );
  }
  handleRiskRatingChange = (event, type, axis) => {
    let rating =
      axis === "likelihood"
        ? likelihoodLevel.indexOf(event.value) + 1
        : riskLevel.indexOf(event.value) + 1;
    this.props.handleRiskRatingChange(type, axis, rating);
    // update rating
    let likelihood =
      axis === "likelihood" ? rating : this.props.riskDetail[type].likelihood;
    let impact =
      axis === "impact" ? rating : this.props.riskDetail[type].impact;
    let riskRating = getRiskRating(likelihood, impact);
    this.props.handleRiskRatingChange(type, "rating", riskRating);
  };
}

const RatingDetail = (props) => {
  return (
    // LEFT SIDE CONTENT ---------------------
    <div style={leftBox}>
      <RatingDetailHeader />
      {props.rights.edit ? (
        <div>
          <RatingContent
            type={"gross"}
            matrix={props.matrix}
            handleRiskRatingChange={props.handleRiskRatingChange}
          />
          <RatingContent
            type={"residual"}
            matrix={props.matrix}
            handleRiskRatingChange={props.handleRiskRatingChange}
          />
          <RatingContent
            type={"target"}
            matrix={props.matrix}
            handleRiskRatingChange={props.handleRiskRatingChange}
          />
        </div>
      ) : (
        <div>
          <RatingContentView type={"gross"} matrix={props.matrix} />
          <RatingContentView type={"residual"} matrix={props.matrix} />
          <RatingContentView type={"target"} matrix={props.matrix} />
        </div>
      )}
    </div>
  );
};

const RatingDetailHeader = () => {
  return (
    // --- TOP ROW HEADERS
    <div style={leftBoxHeader}>
      <div style={leftBoxHeaderText}>
        <span>&nbsp;</span>
      </div>
      <div style={leftBoxHeaderText}>LIKELIHOOD</div>
      <div style={leftBoxHeaderText}>IMPACT</div>
      {/*the last one need some special style to fit the width*/}
      <div style={{ ...leftBoxHeaderText, width: "5rem", paddingRight: 0 }}>
        RATING
      </div>
    </div>
  );
};

const RatingContentView = (props) => {
  return (
    <div style={leftSubBox}>
      <div style={leftBoxHeaderText}>{props.type}</div>

      <div style={leftBoxViewWrapperStyle}>
        <Pill
          content={props.matrix[props.type].likelihood}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={pillViewStyle}
        />
        <div style={leftSubBoxText}>
          {likelihoodLevel[props.matrix[props.type].likelihood - 1]}
        </div>
        <Pill
          content={props.matrix[props.type].impact}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={pillViewStyle}
        />
        <div style={leftSubBoxText}>
          {riskLevel[props.matrix[props.type].impact - 1]}
        </div>
        <Pill
          content={ratingMaping[props.matrix[props.type].rating]}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={pillViewStyle}
        />
      </div>
    </div>
  );
};

const RatingContent = (props) => {
  const handleRiskRatingLikelihoodChange = (event) => {
    props.handleRiskRatingChange(event, props.type, "likelihood");
  };
  const handleRiskRatingImpactChange = (event) => {
    props.handleRiskRatingChange(event, props.type, "impact");
  };
  return (
    <div style={leftSubBox}>
      <div style={leftBoxHeaderText}>{props.type}</div>

      <div style={leftBoxWrapperStyle}>
        <Pill
          content={props.matrix[props.type].likelihood}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={pillStyle}
        />
        <DropDownListButton
          value={likelihoodLevel[props.matrix[props.type].likelihood - 1]}
          data={likelihoodLevel}
          CustomOnChange={handleRiskRatingLikelihoodChange}
          DropDownButtonStyle={riskRatingDropDownButtonStyle}
        />
        <Pill
          content={props.matrix[props.type].impact}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={pillStyle}
        />
        <DropDownListButton
          value={riskLevel[props.matrix[props.type].impact - 1]}
          data={riskLevel}
          CustomOnChange={handleRiskRatingImpactChange}
          DropDownButtonStyle={riskRatingDropDownButtonStyle}
        />
        <Pill
          content={ratingMaping[props.matrix[props.type].rating]}
          color={pillColor(props.matrix[props.type].rating)}
          pillStyle={pillStyle}
        />
      </div>
    </div>
  );
};

const riskRatingDropDownButtonStyle = {
  marginLeft: "5px",
  marginRight: "5px",
  width: "8rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const riskRatingMatrixStyle = {
  margin: "1.5rem",
  transform: "scale(0.97)",
  transformOrigin: "top left",
};
// MAIN CONTAINER

const RiskRatingContentStyle = {
  height: "39rem",
  width: "1101px",
  backgroundColor: mapping["Color_BG_04_solid"],
  borderRadius: "10px",
  boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", // X axis, Y axis ,blur, colour
};

//  --- Right container for matrix.
var rightBox = {
  marginRight: "1.625rem",
  marginTop: "2rem",
  float: "right",
  width: "29.375rem",
  height: "32rem",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};

const leftBoxViewWrapperStyle = {
  paddingTop: "1.2rem",
  display: "inline-flex",
};
const leftBoxWrapperStyle = {
  paddingTop: "0.8rem",
  display: "inline-flex",
};
//  --- Top ribbon header text
var leftBoxHeaderText = {
  width: "10rem",
  paddingLeft: "1rem",
  paddingTop: "1.4rem",
  float: "left",
  ...mapping["Sub Text Tag/H7-blue-tag"],
  color: "rgb(4, 176, 235)",
};

//  --- Text for risk likelihood or impact levels
var leftSubBoxText = {
  width: "8.5rem",
  paddingLeft: "12px",
  float: "left",
  ...mapping["forms/input-default"],
  color: "white",
};
//  --- Pill positioning
const pillViewStyle = {
  float: "left",
  marginLeft: "8px",
};
const pillStyle = {
  float: "left",
  marginTop: "0.4rem",
  marginLeft: "8px",
};
export { RiskRating };
