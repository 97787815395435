import React from "react";
import {
  mapping,
  DropDownListButton,
  RiskEditCardHeader,
  NameCard,
  ButtonSolid,
  NonValidationInput,
  NonValidationTextInput,
  AutoSizeTextArea,
  RiskEditCardAddNew,
  IconSVG,
} from "@aim-mf/styleguide";

import { RiskEditCardStyle } from "../risk-edit-common-style";

const RiskRatingStyle = {
  paddingLeft: "5rem",
  paddingTop: "2.5rem",
};

class RiskAppetite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={RiskRatingStyle}>
        <div style={RiskEditCardStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />
          <div style={{ margin: "1.25rem" }}>
            {this.props.rights.edit === 1 ? (
              <RiskAppetiteContent
                riskAppetite={this.props.riskAppetite}
                riskAppetiteLevel={this.props.riskAppetiteLevel}
                riskAppetiteStatement={this.props.riskAppetiteStatement}
                handleAppetiteStatementChange={
                  this.handleAppetiteStatementChange
                }
                handleAppetiteLevelChange={this.handleAppetiteLevelChange}
                handleAppetiteMetricChange={this.handleAppetiteMetricChange}
                handleAppetiteProviderChange={this.handleAppetiteProviderChange}
                handleAppetiteChange={this.handleAppetiteChange}
                handleRemove={this.handleRemove}
                handleRiskAppetiteSafeZoneChange={
                  this.handleRiskAppetiteSafeZoneChange
                }
              />
            ) : (
              <RiskAppetiteContentView
                riskAppetite={this.props.riskAppetite}
                riskAppetiteLevel={this.props.riskAppetiteLevel}
                riskAppetiteStatement={this.props.riskAppetiteStatement}
              />
            )}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new Tolerance Metric"}
                width={"12rem"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  handleRiskAppetiteSafeZoneChange = (index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index].safe_zone =
      existingAppetite[index].safe_zone === "low" ? "high" : "low";
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAppetiteStatementChange = (value) => {
    this.props.handleRiskAppetiteStatementChange(value);
  };
  handleAppetiteLevelChange = (event) => {
    this.props.handleRiskAppetiteLevelChange(event.value);
  };
  handleAppetiteChange = (value, key, index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index][key] = value;
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAppetiteMetricChange = (value, index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite[index].risk_matrix = value;
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleAddNew = () => {
    let existingAppetite = this.props.riskAppetite;
    let newNumber =
      "RA" +
      (Math.max(
        ...this.props.riskAppetite.map((a) => {
          return a.number.split("RA").pop();
        }),
        0
      ) +
        1);
    existingAppetite.push({
      lower_threshold: 50,
      number: newNumber,
      provided_by: [],
      risk_matrix: "",
      threshold_unit: "%",
      upper_threshold: 50,
      safe_zone: "low",
    });
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };
  handleRemove = (index) => {
    let existingAppetite = this.props.riskAppetite;
    existingAppetite.splice(index, 1);
    let newAppetite = existingAppetite;
    this.props.handleRiskAppetiteChange(newAppetite);
  };

  handleAppetiteProviderChange = (index) => {
    this.props.handleAddUser(
      "riskappetite",
      index,
      this.props.riskAppetite[index].provided_by,
      "Assign Appetite Owner"
    );
  };
}

const RiskAppetiteContent = (props) => {
  return (
    <div style={cardStyle}>
      <div>
        <div style={riskAppetiteStatementWrapperStyle}>
          <RiskAppetiteLevel
            handleAppetiteLevelChange={props.handleAppetiteLevelChange}
            level={props.riskAppetiteLevel}
            levelList={["High", "Medium", "Low"]}
          />
          <RiskAppetiteStatement
            description={props.riskAppetiteStatement}
            handleAppetiteStatementChange={props.handleAppetiteStatementChange}
          />
        </div>
      </div>
      {props.riskAppetite.map((riskAppetite, index) => {
        return (
          <div style={cardBGStyle}>
            <IconSVG
              onClick={() => props.handleRemove(index)}
              name={"delete"}
              wrapperStyle={{ position: "absolute", right: "5px", top: "5px" }}
              color={mapping["Color_Basic_Mute_2"]}
            />
            <div style={riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteNumber
                riskAppetiteNumber={riskAppetite.number}
                index={index}
                handleAppetiteChange={props.handleAppetiteChange}
              />

              <RiskAppetiteMetric
                matrix={riskAppetite.risk_matrix}
                handleAppetiteMetricChange={props.handleAppetiteMetricChange}
                index={index}
                handleAppetiteChange={props.handleAppetiteChange}
              />
            </div>
            <div style={riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteProvider
                provider={riskAppetite.provided_by}
                handleAppetiteProviderChange={
                  props.handleAppetiteProviderChange
                }
                index={index}
              />
              <RiskAppetiteThreshold
                safe_zone={riskAppetite.safe_zone}
                currentUnit={riskAppetite.threshold_unit}
                unitList={["%", ".00"]}
                riskAppetiteLow={riskAppetite.lower_threshold}
                riskAppetiteHigh={riskAppetite.upper_threshold}
                index={index}
                handleAppetiteChange={props.handleAppetiteChange}
                handleRiskAppetiteSafeZoneChange={
                  props.handleRiskAppetiteSafeZoneChange
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
const RiskAppetiteStatement = (props) => {
  // console.log(props.description);
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>
        Risk Appetite Statement
      </div>
      <div style={{ paddingTop: "1.25rem" }}>
        <AutoSizeTextArea
          fieldstyle={MultilineInputFieldStyle}
          value={props.description}
          CustomizedOnChange={props.handleAppetiteStatementChange}
        />
      </div>
    </div>
  );
};
const RiskAppetiteProvider = (props) => {
  const OwnerChange = () => {
    props.handleAppetiteProviderChange(props.index);
  };
  return (
    <div style={{ marginRight: "2rem", width: "10rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Provided By</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.provider != null &&
          Object.keys(props.provider).length !== 0 &&
          props.provider.map((owner) => {
            return (
              <NameCard
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={"18px"}
        width={"52px"}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const RiskAppetiteLevel = (props) => {
  return (
    <div style={{ width: "10rem", marginRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Risk Appetite Level</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.level}
          data={props.levelList}
          CustomOnChange={props.handleAppetiteLevelChange}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
        />
      </div>
    </div>
  );
};
const RiskAppetiteMetric = (props) => {
  const onChange = (value) => {
    props.handleAppetiteMetricChange(value, props.index);
  };
  return (
    <div style={{ marginRight: "3rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>
        Risk Tolerance Metric
      </div>
      <div style={{ paddingTop: "1.25rem" }}>
        <AutoSizeTextArea
          fieldstyle={{ ...MultilineInputFieldStyle, width: "58.2rem" }}
          value={props.matrix}
          CustomizedOnChange={onChange}
        />
      </div>
    </div>
  );
};
const RiskAppetiteThreshold = (props) => {
  const handleRiskAppetiteUnitChange = (event) => {
    props.handleAppetiteChange(event.value, "threshold_unit", props.index);
  };
  //since it is a single value, instead of change the database struct, put upper=lower to achieve the same effect
  const handleRiskAppetiteLowerThresholdChange = (event) => {
    props.handleAppetiteChange(event.value, "lower_threshold", props.index);
    props.handleAppetiteChange(event.value, "upper_threshold", props.index);
  };
  const handleRiskAppetiteHigherThresholdChange = (event) => {
    props.handleAppetiteChange(event.value, "upper_threshold", props.index);
  };
  return (
    <div style={{ marginRight: "6rem" }}>
      <div style={{ position: "relative" }}>
        <div style={RiskEditCardStyle.sectionTitleStyle}>Tolerance limit</div>
        {/*<div className={"div_hover"}>*/}
        {/*  <IconSVG*/}
        {/*    name={"flip"}*/}
        {/*    color={mapping["Color_Basic_Primary"]}*/}
        {/*    wrapperStyle={{ position: "absolute", top: 0, right: 0 }}*/}
        {/*    onClick={() => {*/}
        {/*      props.handleRiskAppetiteSafeZoneChange(props.index);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.currentUnit}
          data={props.unitList}
          CustomOnChange={handleRiskAppetiteUnitChange}
          DropDownButtonStyle={riskThresholdTypeDropDownButtonStyle}
          disabled={props.disabled}
        />
        <ThresholdLowerInput
          safe_zone={props.safe_zone}
          unit={props.currentUnit}
          index={props.index}
          lower={props.riskAppetiteLow}
          handleRiskAppetiteLowerThresholdChange={
            handleRiskAppetiteLowerThresholdChange
          }
        />

        {/*<ThresholdMiddleRange*/}
        {/*  unit={props.currentUnit}*/}
        {/*  lower={props.riskAppetiteLow}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*/>*/}
        {/*<ThresholdUpperInput*/}
        {/*  safe_zone={props.safe_zone}*/}
        {/*  unit={props.currentUnit}*/}
        {/*  index={props.index}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*  handleRiskAppetiteHigherThresholdChange={*/}
        {/*    handleRiskAppetiteHigherThresholdChange*/}
        {/*  }*/}
        {/*/>*/}
      </div>
    </div>
  );
};

const RiskAppetiteContentView = (props) => {
  return (
    <div style={cardStyle}>
      <div>
        <div style={riskAppetiteStatementWrapperStyle}>
          <RiskAppetiteLevelView
            level={props.riskAppetiteLevel}
            levelList={["High", "Medium", "Low"]}
          />
          <RiskAppetiteStatementView
            description={props.riskAppetiteStatement}
          />
        </div>
      </div>
      {props.riskAppetite.map((riskAppetite, index) => {
        return (
          <div>
            <div style={riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteNumber riskAppetiteNumber={riskAppetite.number} />
              <RiskAppetiteMetricView matrix={riskAppetite.risk_matrix} />
            </div>
            <div style={riskAppetiteLevelWrapperStyle}>
              <RiskAppetiteProviderView provider={riskAppetite.provided_by} />
              <RiskAppetiteThresholdView
                currentUnit={riskAppetite.threshold_unit}
                riskAppetiteLow={riskAppetite.lower_threshold}
                riskAppetiteHigh={riskAppetite.upper_threshold}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
const RiskAppetiteStatementView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>
        Risk Appetite Statement
      </div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} width={"51.5rem"} />
      </div>
    </div>
  );
};
const RiskAppetiteProviderView = (props) => {
  return (
    <div style={{ marginRight: "2rem", width: "10rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Provided By</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.provider != null &&
          Object.keys(props.provider).length !== 0 &&
          props.provider.map((owner) => {
            return (
              <NameCard
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const RiskAppetiteLevelView = (props) => {
  return (
    <div style={{ width: "10rem", marginRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Risk Appetite Level</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.level}
          data={props.levelList}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const RiskAppetiteMetricView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>
        Risk Appetite Metric
      </div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.matrix} width={"58.2rem"} />
      </div>
    </div>
  );
};
const RiskAppetiteThresholdView = (props) => {
  return (
    <div style={{ marginRight: "6rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Tolerance limit</div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.currentUnit}
          data={props.unitList}
          DropDownButtonStyle={riskThresholdTypeDropDownButtonStyle}
          disabled={true}
        />
        <ThresholdLowerInputView
          unit={props.currentUnit}
          lower={props.riskAppetiteLow}
        />

        {/*<ThresholdMiddleRange*/}
        {/*  unit={props.currentUnit}*/}
        {/*  lower={props.riskAppetiteLow}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*/>*/}
        {/*<ThresholdUpperInputView*/}
        {/*  unit={props.currentUnit}*/}
        {/*  upper={props.riskAppetiteHigh}*/}
        {/*/>*/}
      </div>
    </div>
  );
};
const RiskAppetiteNumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>NO.</div>
      <div style={contentTextStyle}>{props.riskAppetiteNumber}</div>
    </div>
  );
};

const TextContent = (props) => {
  return (
    <div
      style={
        props.short
          ? { ...ShortTextWrapperStyle, width: props.width }
          : { ...TextWrapperStyle, width: props.width }
      }
    >
      <div style={TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};

const ThresholdLowerInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safe_zone === "low"
            ? ThresholdSafeInputFieldStyle
            : ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        onChange={props.handleRiskAppetiteLowerThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};
const ThresholdMiddleRange = (props) => {
  return (
    <div style={thresholdMiddleStyle}>
      {props.lower}
      {props.unit === "%" ? "%" : ""}-{props.upper}
      {props.unit === "%" ? "%" : ""}
    </div>
  );
};
const ThresholdUpperInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={
          props.safe_zone === "high"
            ? ThresholdSafeInputFieldStyle
            : ThresholdDangerInputFieldStyle
        }
        value={props.upper}
        onChange={props.handleRiskAppetiteHigherThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};

const ThresholdLowerInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={ThresholdSafeInputFieldStyle}
        value={props.lower}
        disabled={true}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};
const ThresholdUpperInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={ThresholdDangerInputFieldStyle}
        value={props.upper}
        disabled={true}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};
const TextWrapperStyle = {
  minHeight: "32px",
  padding: "0.25rem",
  height: "fit-content",
  width: "51.5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "1px solid rgba(151,151,151,0)",
};
const ShortTextWrapperStyle = {
  minHeight: "32px",
  padding: "0.25rem",
  height: "fit-content",
  width: "29.625rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "1px solid rgba(151,151,151,0)",
  marginRight: "1.5rem",
};
const TextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  padding: "0.25rem",
};
const cardStyle = {
  width: "65.875rem",
  height: "fit-content",
};
const cardBGStyle = {
  position: "relative",
  width: "65.875rem",
  height: "fit-content",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};
const riskTierDropDownButtonStyle = {
  width: "10rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};

const MultilineInputFieldStyle = {
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  height: "auto",
  border: "none",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  width: "51.5rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  color: mapping["Color_Basic_Light"],
  outline: "none",
};

const riskAppetiteStatementWrapperStyle = {
  paddingBottom: "1.25rem",
  display: "inline-flex",
};
const riskAppetiteLevelWrapperStyle = {
  padding: "1.25rem",
  paddingRight: 0,
  display: "inline-flex",
  width: "100%",
  //justifyContent: "space-between",
};
const riskThresholdTypeDropDownButtonStyle = {
  width: "3.5rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};

const ThresholdSafeInputFieldStyle = {
  height: "2rem",
  width: "5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  fontSize: "12px",
  padding: "4px",
  textAlign: "center",
  color: mapping["Color_Basic_Light"],
  border: "2px solid #3CA785",
  borderRadius: "4px",
  boxShadow: "none",
};
const ThresholdSymbolStyle = {
  position: "absolute",
  color: mapping["Color_Basic_Light"],
  fontSize: "12px",
  zIndex: 1,
  top: "6px",
  left: "6px",
};
const ThresholdUnitStyle = {
  position: "absolute",
  color: mapping["Color_Basic_Light"],
  fontSize: "12px",
  zIndex: 1,
  top: "7px",
  right: "6px",
};
const thresholdMiddleStyle = {
  height: "2rem",
  width: "8rem",
  backgroundColor: mapping["Color_KRI_Middle_Background"],
  fontSize: "12px",
  padding: "4px",
  paddingTop: "6px",
  textAlign: "center",
  color: "black",
};
const ThresholdDangerInputFieldStyle = {
  height: "2rem",
  width: "5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  fontSize: "12px",
  padding: "4px",
  textAlign: "center",
  color: mapping["Color_Basic_Light"],
  border: "2px solid #FF0000",
  borderRadius: "4px",
  boxShadow: "none",
};
const contentTextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  paddingTop: "1.7rem",
  paddingBottom: "0.5rem",
};
export { RiskAppetite };
