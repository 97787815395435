import React from "react";
import {
  mapping,
  ButtonSolid,
  RiskEditCardHeader,
  BarChart,
  NameCard,
  IconSVG,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";
import { RiskEditCardStyle } from "../risk-edit-common-style";

const KRIPeriodCount = 6;

class Summary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      BarFocused: "",
      BarCategory: "",
      BarCategoryFieldName: "",
    };
  }

  render() {
    // the data structure must be field name, value and color
    let strongControlNumber = this.props.riskDetail.controls.filter(
      (control) => {
        return control.strength === "Strong";
      }
    ).length;

    let moderateControlNumber = this.props.riskDetail.controls.filter(
      (control) => {
        return control.strength === "Moderate";
      }
    ).length;

    let weakControlNumber = this.props.riskDetail.controls.filter((control) => {
      return control.strength === "Weak";
    }).length;

    let controlChartData = [
      {
        strength:
          "Strong\n" +
          Math.round(
            (strongControlNumber * 100) /
              Math.max(this.props.riskDetail.controls.length, 1)
          ) +
          "%",
        number: strongControlNumber,
        color: mapping["Color_Platform_RiskLow"],
        outline: "3px solid #00A6FF",
      },
      {
        strength:
          "Moderate\n" +
          Math.round(
            (moderateControlNumber * 100) /
              Math.max(this.props.riskDetail.controls.length, 1)
          ) +
          "%",
        number: moderateControlNumber,
        color: mapping["Color_Platform_RiskMed"],
      },
      {
        strength:
          "Weak\n" +
          Math.round(
            (weakControlNumber * 100) /
              Math.max(this.props.riskDetail.controls.length, 1)
          ) +
          "%",
        number: weakControlNumber,
        color: mapping["Color_Platform_RiskHigh"],
      },
    ];
    let actionPlanChartData = [
      {
        status: "On Track",
        number: this.props.riskDetail.actionplans.filter((AP) => {
          return AP.status === "On Track";
        }).length,
        color: mapping["Color_Platform_RiskLow"],
      },
      {
        status: "Overdue",
        number: this.props.riskDetail.actionplans.filter((AP) => {
          return AP.status === "Overdue";
        }).length,
        color: mapping["Color_Platform_RiskHigh"],
      },
      {
        status: "In Progress",
        number: this.props.riskDetail.actionplans.filter((AP) => {
          return AP.status === "In Progress";
        }).length,
        color: "#C5D0DE",
      },
    ];
    let KRIChartData = this.KRIChartData(this.props.riskDetail.KRIs);

    let chartMax = 10;

    return (
      <div style={RiskSummaryStyle}>
        <div style={RiskEditCardStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          {/*chart box*/}
          <div style={{ display: "flex", paddingTop: "4rem" }}>
            <div style={{ paddingLeft: "1.43rem" }}>
              <ChartBlock
                max={chartMax}
                height={"12rem"}
                title={"Control Strength"}
                data={controlChartData}
                onBarClick={this.onBarClick}
                onViewAllClick={this.onViewAllClick}
                filter={this.state.BarCategory}
                BarFocused={this.state.BarFocused}
              />
            </div>
            <div style={{ paddingLeft: "1.43rem" }}>
              <ChartBlock
                max={chartMax}
                height={"11.2rem"}
                title={"Action Plan Summary"}
                data={actionPlanChartData}
                onBarClick={this.onBarClick}
                onViewAllClick={this.onViewAllClick}
                filter={this.state.BarCategory}
                BarFocused={this.state.BarFocused}
              />
            </div>
            <div style={{ paddingLeft: "1.43rem" }}>
              <ChartBlock
                max={chartMax}
                height={"11.2rem"}
                title={"KRI Summary"}
                data={KRIChartData}
                onBarClick={this.onBarClick}
                onViewAllClick={this.onViewAllClick}
                filter={this.state.BarCategory}
                BarFocused={this.state.BarFocused}
              />
            </div>
          </div>

          {/*detial information box*/}
          <div style={detailInfoBox}>
            <ChartDetailBlock
              riskDetail={this.props.riskDetail}
              type={this.state.BarFocused}
              category={this.state.BarCategory}
              categoryFieldName={this.state.BarCategoryFieldName}
            />
          </div>
        </div>
      </div>
    );
  }

  onBarClick = (title, category, categoryFieldName) => {
    this.setState({
      BarFocused: title,
      BarCategory: category,
      BarCategoryFieldName: categoryFieldName,
    });
  };
  onViewAllClick = (title) => {
    this.setState({
      BarFocused: title,
      BarCategory: "All",
    });
  };

  KRIChartData = (KRIList) => {
    let green = 0,
      amber = 0,
      red = 0,
      notDue = 0;
    KRIList.map((kri) => {
      if (kri.value.length === 0) {
        notDue += 1;
      } else if (kri.safe_zone === "low") {
        let value = kri.value
          .sort((a, b) => {
            return a.period > b.period ? 1 : -1;
          })
          .slice(-KRIPeriodCount);
        let redValue = value.filter((value) => {
          return value.value > kri.upper_threshold;
        });
        let amberValue = value.filter((value) => {
          return (
            value.value < kri.upper_threshold &&
            value.value > kri.lower_threshold
          );
        });
        if (redValue.length > 0) {
          red += 1;
        } else if (amberValue.length > 0) {
          amber += 1;
        } else {
          green += 1;
        }
      } else if (kri.safe_zone === "high") {
        let value = kri.value
          .sort((a, b) => {
            return a.period > b.period ? 1 : -1;
          })
          .slice(-KRIPeriodCount);
        let redValue = value.filter((value) => {
          return value.value < kri.lower_threshold;
        });
        let amberValue = value.filter((value) => {
          return (
            value.value > kri.lower_threshold &&
            value.value < kri.upper_threshold
          );
        });
        if (redValue.length > 0) {
          red += 1;
        } else if (amberValue.length > 0) {
          amber += 1;
        } else {
          green += 1;
        }
      }
    });
    let KRIChartData = [
      {
        threshold: "Green\n ",
        number: green,
        color: mapping["Color_Platform_RiskLow"],
      },
      {
        threshold: "Amber\n ",
        number: amber,
        color: mapping["Color_Platform_RiskMed"],
      },
      {
        threshold: "Red\n",
        number: red,
        color: mapping["Color_Platform_RiskHigh"],
      },
      {
        threshold: "Not Due\n",
        number: notDue,
        color: "#7F7F7F",
      },
    ];
    return KRIChartData;
  };
}

const ChartBlock = (props) => {
  const onBarClick = (event) => {
    props.onBarClick(
      props.title,
      event.point.category,
      event.series.categoryField
    );
  };
  const viewAll = () => {
    props.onViewAllClick(props.title);
  };
  return (
    <div>
      <div style={{ display: "inline-flex" }}>
        <div style={{ paddingTop: "0.4rem", width: "17rem" }}>
          <div style={RiskEditCardStyle.sectionTitleStyle}>{props.title}</div>
        </div>
        <ButtonSolid
          name={"View All"}
          size={"Small"}
          height={"1rem"}
          width={"4rem"}
          color={"Primary"}
          clickEvent={viewAll}
        />
      </div>
      <div
        style={{
          ...BoxStyle,
          outline:
            props.filter === "All"
              ? props.BarFocused === props.title
                ? "3px solid #00A6FF"
                : "none"
              : "none",
        }}
      >
        <BarChart
          max={props.max}
          height={props.height}
          data={props.data}
          onBarClick={onBarClick}
        />
      </div>
    </div>
  );
};
const ChartDetailBlock = (props) => {
  let data = [];
  let category = props.category;
  let name = "";
  switch (props.type) {
    case "Control Strength":
      data = props.riskDetail.controls;
      category = category.split("\n")[0];
      name = "Control";
      break;
    case "Action Plan Summary":
      data = props.riskDetail.actionplans;
      name = "Action Plan";
      break;
    case "KRI Summary":
      data = props.riskDetail.KRIs;
      name = "Key Risk Indicator";
      break;
  }

  if (category !== "All") {
    data = data.filter((data) => {
      return data[props.categoryFieldName] === category;
    });
  }

  return (
    <div>
      <div>
        <ChartDetailBlockHeader
          name={name}
          data={data.length === 0 ? [] : Object.keys(data[0])}
        />
      </div>
      <div>
        {data.map((data) => {
          return <ChartDetailBlockContent name={name} data={data} />;
        })}
      </div>
    </div>
  );
};

const ChartDetailBlockHeader = (props) => {
  if (props.name === "") {
    return <div />;
  }
  return (
    <div
      style={{
        display: "inline-flex",
        width: "100%",
        justifyContent: "space-between",
        padding: "1.25rem 4rem 0.825rem 2.5rem",
      }}
    >
      {/* shared title */}
      <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "5rem" }}>
        No.
      </div>
      <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "15rem" }}>
        {props.name}
      </div>
      <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "10rem" }}>
        Owner
      </div>
      {/* Action Plan title */}
      {props.name === "Action Plan" && (
        <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "8rem" }}>
          Timeline
        </div>
      )}
      {props.name === "Action Plan" && (
        <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "5rem" }}>
          Status
        </div>
      )}

      {/* Control KRI shared title */}
      {props.name !== "Action Plan" && (
        <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "5rem" }}>
          Frequency
        </div>
      )}
      {props.name !== "Action Plan" && (
        <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "5rem" }}>
          Type
        </div>
      )}
      {/* Control title */}
      {props.name === "Control" && (
        <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "5rem" }}>
          Strength
        </div>
      )}
      {/* KRI title */}
      {props.name === "KRI" && (
        <div style={{ ...RiskEditCardStyle.sectionTitleStyle, width: "5rem" }}>
          Threshold
        </div>
      )}
      {/*{props.name === "Control" && <div style={{ width: "2rem" }} />}*/}
      {/*{props.name === "Control" && <div style={{ width: "2rem" }} />}*/}
    </div>
  );
};
const ChartDetailBlockContent = (props) => {
  if (Object.keys(props.data).length === 0) {
    return <div />;
  }
  return (
    <div style={DetailBoxContentWrapperStyle}>
      <div style={DetailBoxContentBackgroundStyle}>
        <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
          {props.data.number}
        </div>
        <div style={{ width: "15rem", ...DetailBoxContentStyle }}>
          {props.data.description}
        </div>
        <div style={{ width: "10rem", ...DetailBoxContentStyle }}>
          {props.data.owner.length !== 0 &&
            props.data.owner.map((owner, index) => {
              return (
                <div key={index}>
                  <NameCard
                    UserContentStyle={{ paddingBottom: 0 }}
                    initial={owner.display_name
                      .split(" ")
                      .map((a) => {
                        return a[0].toUpperCase();
                      })
                      .join("")}
                    name={owner.display_name}
                    title={owner.title}
                  />
                  <div style={{ height: "5px" }} />
                </div>
              );
            })}
        </div>
        {/* Action Plan content */}
        {props.name === "Action Plan" && (
          <div style={{ width: "8rem", ...DetailBoxContentStyle }}>
            {props.data.deadline}
          </div>
        )}
        {props.name === "Action Plan" && (
          <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
            {props.data.status}
          </div>
        )}

        {/* Control KRI shared content */}
        {props.name !== "Action Plan" && (
          <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
            {props.data.frequency}
          </div>
        )}
        {props.name === "Control" && (
          <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
            {props.data.control_type}
          </div>
        )}
        {props.name === "KRI" && (
          <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
            {props.data.KRI_type}
          </div>
        )}
        {/* Control content */}
        {props.name === "Control" && (
          <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
            {props.data.strength}
          </div>
        )}
        {/* KRI content */}
        {props.name === "KRI" && (
          <div style={{ width: "5rem", ...DetailBoxContentStyle }}>
            Threshold
          </div>
        )}
        {/*{props.name === "Control" &&*/}
        {/*  (props.data.control_type === "Manual" ? (*/}
        {/*    <div*/}
        {/*      className={"div_hover"}*/}
        {/*      style={{*/}
        {/*        ...DetailControlCSAIconStyle,*/}
        {/*        backgroundColor:*/}
        {/*          props.data.id === undefined*/}
        {/*            ? "grey"*/}
        {/*            : mapping["Color_Basic_Primary"],*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <IconSVG*/}
        {/*        wrapperStyle={{ width: "100%", height: "100%" }}*/}
        {/*        name={"listNumber"}*/}
        {/*        color={"white"}*/}
        {/*        onClick={() => {*/}
        {/*          if (props.data.id !== undefined) {*/}
        {/*            navigateToUrl(*/}
        {/*              "/control-self-assessment/" + props.data.id + "/"*/}
        {/*            );*/}
        {/*          }*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  ) : (*/}
        {/*    <div style={{ width: "2rem" }} />*/}
        {/*  ))}*/}
        {/*{props.name === "Control" && (*/}
        {/*  <div*/}
        {/*    className={"div_hover"}*/}
        {/*    style={{*/}
        {/*      ...DetailControlCSAIconStyle,*/}
        {/*      backgroundColor: props.data.id === undefined ? "grey" : "#007BFF",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <IconSVG*/}
        {/*      wrapperStyle={{ width: "100%", height: "100%" }}*/}
        {/*      name={"barChart"}*/}
        {/*      color={"white"}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

const RiskSummaryStyle = {
  paddingLeft: "5rem",
  paddingTop: "4rem",
};

const detailInfoBox = {
  margin: "1.43rem",
  height: "27rem",
  width: "65.875rem",
  backgroundColor: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};

var BoxStyle = {
  marginTop: "20px",
  width: "20.96rem",
  height: "11.438rem",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};
const DetailBoxContentWrapperStyle = {
  paddingLeft: "1rem",
  paddingRight: "2.5rem",
  width: "100%",
};
const DetailBoxContentBackgroundStyle = {
  display: "inline-flex",
  width: "100%",
  padding: "1rem 1.5rem 1rem 1.5rem",
  justifyContent: "space-between",
  backgroundColor: "#3E4E6C",
  height: "fit-content",
};

const DetailBoxContentStyle = {
  ...mapping["Font_Summary_Detail_Row"],
  height: "fit-content",
  margin: "auto 0 auto 0",
};

const DetailControlCSAIconStyle = {
  margin: "auto 0 auto 0",
  backgroundColor: mapping["Color_Basic_Primary"],
  borderRadius: "4px",
  width: "2rem",
  height: "2rem",
};

export { Summary };
