import React from "react";
import { SideBar } from "./sideBar";
import { RiskBasicInfo } from "./riskBasicInfo";
import { RiskSummary } from "./riskSummary";
import { RiskEditManagement } from "./riskEditManagement";
import {
  AddUser,
  TaskForm,
  ConfirmationPopup,
  Loading,
  mapping,
} from "@aim-mf/styleguide";
import { KRIValueEntry } from "./riskRegisterComponents/KRIValueEntry";
import moment from "moment";

const RiskEditSideStyle = {
  height: "fit-content",
  width: "120rem",
  display: "inline-flex",
};
const RiskEditMainStyle = {
  height: "fit-content",
  width: "100%",
};

class RiskEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "Risk Summary",
      currentUser: {},
      newRiskDetail: JSON.parse(JSON.stringify(this.props.riskDetail)),
      AddUser: false,
      createTask: false,
      kriEntry: { display: false },
      title: "",
      Confirmation: { display: false },
    };
  }

  componentDidMount() {
    window.addEventListener("beforeunload", (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "you are about to leave the page";
    });
  }

  APStatusGenerated = (riskDetail) => {
    let newRiskDetail = riskDetail;
    newRiskDetail.actionplans.forEach((AP) => {
      if (new Date() > new Date(AP.deadline + " 23:59:59")) {
        AP.status = "Overdue";
      } else if (new Date() < new Date(AP.deadline + " 00:00:00")) {
        AP.status = "On Track";
      } else {
        AP.status = "In Progress";
      }
    });
    return newRiskDetail;
  };

  decodeUserDetail = (field, riskDetail) => {
    let newRiskDetail = riskDetail;
    if (field === "owner") {
      newRiskDetail.owner.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newRiskDetail.owner[index] = ownerDetail[0];
      });
    } else if (field === "delegate") {
      newRiskDetail.delegate.forEach((username, index) => {
        let ownerDetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        newRiskDetail.delegate[index] = ownerDetail[0];
      });
    } else if (field === "riskappetite") {
      newRiskDetail[field].forEach((item) => {
        item.provided_by.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.provided_by[index] = ownerdetail[0];
        });
      });
    } else {
      newRiskDetail[field].forEach((item) => {
        item.owner.forEach((username, index) => {
          let ownerdetail = this.props.userList.filter((user) => {
            return user.username === username;
          });
          item.owner[index] = ownerdetail[0];
        });
      });
    }
    return newRiskDetail;
  };
  encodeUserDetail = (field, riskDetail) => {
    if (field === "owner") {
      riskDetail.owner = riskDetail.owner.map((a) => a.username);
    } else if (field === "delegate") {
      riskDetail.delegate = riskDetail.delegate.map((a) => a.username);
    } else if (field === "riskappetite") {
      riskDetail[field].forEach((item, index) => {
        riskDetail[field][index].provided_by = item.provided_by.map(
          (a) => a.username
        );
      });
    } else {
      riskDetail[field].forEach((item, index) => {
        riskDetail[field][index].owner = item.owner.map((a) => a.username);
      });
    }
    return riskDetail;
  };

  removeFieldID = (fieldList, data) => {
    delete data.id;
    delete data.riskappetite.id;
    fieldList.forEach((field) => {
      if (data[field] !== undefined) {
        data[field].forEach((item, index) => {
          delete data[field][index].id;
        });
      }
      // need to remove extra CSA ID in the controls
      if (field === "controls") {
        data[field].forEach((item, index) => {
          delete data[field][index].control_self_assessment_id;
        });
      }
    });

    return data;
  };

  removeKRIValue = (data) => {
    data.KRIs.forEach((kri) => {
      delete kri.value;
    });
    return data;
  };

  packRiskDataForSubmission = () => {
    let data = JSON.parse(JSON.stringify(this.state.newRiskDetail));
    data = this.encodeUserDetail("controls", data);
    data = this.encodeUserDetail("KRIs", data);
    data = this.encodeUserDetail("actionplans", data);
    data = this.encodeUserDetail("riskappetite", data);
    data = this.encodeUserDetail("owner", data);
    data = this.encodeUserDetail("delegate", data);
    let riskID = data.id;
    data = this.removeFieldID(
      ["controls", "KRIs", "actionplans", "riskappetite"],
      data
    );
    data = this.removeKRIValue(data);
    data.number = this.state.newRiskDetail.riskNumber;
    return [data, riskID];
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(nextProps.riskDetail) !==
        JSON.stringify(this.props.riskDetail) &&
      nextProps.userList.length !== 0
    ) {
      let newRiskDetail = this.decodeUserDetail(
        "controls",
        JSON.parse(JSON.stringify(nextProps.riskDetail))
      );
      newRiskDetail = this.decodeUserDetail("KRIs", newRiskDetail);
      newRiskDetail = this.decodeUserDetail("actionplans", newRiskDetail);
      newRiskDetail = this.decodeUserDetail("owner", newRiskDetail);
      newRiskDetail = this.decodeUserDetail("delegate", newRiskDetail);
      newRiskDetail = this.decodeUserDetail("riskappetite", newRiskDetail);
      newRiskDetail = this.APStatusGenerated(newRiskDetail);
      this.setState({ newRiskDetail: newRiskDetail });
    }

    // populate controls
    if (
      JSON.stringify(nextProps.controlAutoPopulateDetail) !==
        JSON.stringify(this.props.controlAutoPopulateDetail) &&
      Object.keys(nextProps.controlAutoPopulateDetail).length !== 0
    ) {
      let newRiskDetail = JSON.parse(JSON.stringify(this.state.newRiskDetail));

      let populatedControl = JSON.parse(
        JSON.stringify(nextProps.controlAutoPopulateDetail)
      );

      // decode owner for controls only
      populatedControl.owner.forEach((username, index) => {
        let ownerdetail = this.props.userList.filter((user) => {
          return user.username === username;
        });
        populatedControl.owner[index] = ownerdetail[0];
      });

      newRiskDetail.controls[this.props.controlSearchIndex] = populatedControl;
      this.setState({ newRiskDetail: newRiskDetail });
    }
  }

  render() {
    // eslint-disable-next-line no-console
    // console.log("risk edit",this.props.fullRiskList);
    if (Object.keys(this.state.newRiskDetail).length === 0) {
      return <div />;
    }

    let currentdata = JSON.parse(JSON.stringify(this.state.newRiskDetail));
    currentdata = this.encodeUserDetail("controls", currentdata);
    currentdata = this.encodeUserDetail("KRIs", currentdata);
    currentdata = this.encodeUserDetail("actionplans", currentdata);
    currentdata = this.encodeUserDetail("riskappetite", currentdata);
    currentdata = this.encodeUserDetail("owner", currentdata);
    currentdata = this.encodeUserDetail("delegate", currentdata);
    let disableSendDelegate =
      JSON.stringify(currentdata.delegate) ===
      JSON.stringify(this.props.riskDetail.delegate);
    let disableSendOwner =
      JSON.stringify(currentdata.owner) ===
      JSON.stringify(this.props.riskDetail.owner);

    //some field it should not check on diff
    // currentdata.owner = this.props.riskDetail.owner;
    // currentdata.delegate = this.props.riskDetail.delegate;
    currentdata.versions = this.props.riskDetail.versions;

    let disableSubmit =
      JSON.stringify(currentdata) === JSON.stringify(this.props.riskDetail);
    let disableSaveChange =
      JSON.stringify(currentdata) === JSON.stringify(this.props.riskDetail);

    let parentRiskList = this.props.fullRiskList.filter((risk) => {
      return risk.isParentRisk === true;
    });
    let parentRiskDropDownItem = parentRiskList.map(
      ({ name, id, category }) => ({ name, id, category })
    );

    let sideMenuParentRisk = this.props.riskDetail.isParentRisk
      ? this.props.riskDetail
      : this.props.fullRiskList.filter((risk) => {
          return risk.sourceRiskID == this.props.riskDetail.parentRisk_id;
        })[0];
    let sideMenuSubrisk = this.props.riskDetail.isParentRisk
      ? this.props.fullRiskList.filter((risk) => {
          return this.props.riskDetail.sub_risks
            .map((a) => a.name)
            .includes(risk.name);
        })
      : this.props.fullRiskList.filter((risk) => {
          return risk.parentRisk_id == this.props.riskDetail.parentRisk_id;
        });
    let sideMenuData = {
      parentRisk: sideMenuParentRisk,
      subRisk: sideMenuSubrisk,
    };

    return (
      <div id={"risk-edit-wrapper"} style={RiskEditSideStyle}>
        <div
          style={Object.assign(
            {},
            { display: "inline-flex", width: "100%" },
            this.state.AddUser ||
              this.state.createTask ||
              this.state.Confirmation.display ||
              this.props.loading ||
              this.state.kriEntry.display
              ? BlurEffectStyle
              : { dummy: "dummy" }
          )}
        >
          <SideBar
            riskList={sideMenuData}
            focusedID={this.props.riskDetail.sourceRiskID}
            handleUpdateWithNewRiskDetailToLatest={
              this.props.handleUpdateWithNewRiskDetailToLatest
            }
          />
          <div style={RiskEditMainStyle}>
            <RiskBasicInfo
              riskDetail={this.state.newRiskDetail}
              parentRiskTier={this.props.parentRiskTier}
              userList={this.props.userList}
              handleTabChange={this.handleTabChange}
              handleRiskTierChange={this.handleRiskTierChange}
              handleSubmitChange={this.handleSubmitChange}
              handleAddUser={this.handleAddUser}
              handleUpdateWithNewRiskDetail={
                this.props.handleUpdateWithNewRiskDetail
              }
              rights={this.props.riskDetail.access_right}
              disableSubmit={disableSubmit}
              disableSendDelegate={disableSendDelegate}
              disableSendOwner={disableSendOwner}
              disableSaveChange={disableSaveChange}
              handleApprove={this.handleApprove}
              handleSendToDelegate={this.handleSendToDelegate}
              handleSendToOwner={this.handleSendToOwner}
              handleSaveAsDraft={this.handleSaveAsDraft}
              handleRiskDescriptionChange={this.handleRiskDescriptionChange}
              handleCategoryChange={this.handleCategoryChange}
              handleParentRiskChange={this.handleParentRiskChange}
              CategoryList={this.props.CategoryList}
              parentRiskDropDownItem={parentRiskDropDownItem}
              handleConfirmation={this.handleConfirmation}
              handleITRiskTypeChange={this.handleITRiskTypeChange}
              handleNonITRiskTypeChange={this.handleNonITRiskTypeChange}
            />
            {this.state.display === "Risk Summary" && (
              <RiskSummary
                lastEdit={
                  this.state.newRiskDetail.createdUser === "SYSTEM"
                    ? "SYSTEM"
                    : this.props.userList.filter((a) => {
                        return (
                          a.username === this.state.newRiskDetail.createdUser
                        );
                      })[0].display_name
                }
                riskDetail={this.state.newRiskDetail}
                handleRiskAppetiteChange={this.handleRiskAppetiteChange}
                handleAddUser={this.handleAddUser}
                handleRiskRatingChange={this.handleRiskRatingChange}
                rights={this.props.riskDetail.access_right}
                handleCreateTask={this.handleCreateTask}
                handleRiskAppetiteLevelChange={
                  this.handleRiskAppetiteLevelChange
                }
                handleRiskAppetiteStatementChange={
                  this.handleRiskAppetiteStatementChange
                }
              />
            )}
            {this.state.display === "Risk Management" && (
              <RiskEditManagement
                lastEdit={
                  this.state.newRiskDetail.createdUser === "SYSTEM"
                    ? "SYSTEM"
                    : this.props.userList.filter((a) => {
                        return (
                          a.username === this.state.newRiskDetail.createdUser
                        );
                      })[0].display_name
                }
                handleKRIEntry={this.handleKRIEntry}
                riskDetail={this.state.newRiskDetail}
                handleRiskControlChange={this.handleRiskControlChange}
                handleRiskDriverChange={this.handleRiskDriverChange}
                handleRiskConseqChange={this.handleRiskConseqChange}
                handleKRIChange={this.handleKRIChange}
                handleActionPlanChange={this.handleActionPlanChange}
                handleAddUser={this.handleAddUser}
                rights={this.props.riskDetail.access_right}
                searching={this.props.searching}
                handleSearchControls={this.props.handleSearchControls}
                controlsList={this.props.controlsList}
                controlSearchIndex={this.props.controlSearchIndex}
                handleControlDetailRequest={
                  this.props.handleControlDetailRequest
                }
                handleCreateTask={this.handleCreateTask}
              />
            )}
          </div>
        </div>
        {this.state.AddUser && (
          <AddUser
            title={this.state.title}
            userList={this.props.userList}
            currentUser={this.state.currentUser}
            handleAddUserClose={this.handleAddUserClose}
            handleAddUserSubmit={this.handleAddUserSubmit}
          />
        )}
        {this.state.createTask && (
          <TaskForm
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ createTask: false });
            }}
            submitFunction={this.taskSubmitFunction}
          />
        )}
        {this.state.Confirmation.display && (
          <ConfirmationPopup
            title={this.state.Confirmation.title}
            content={this.state.Confirmation.content}
            cancelFunction={this.state.Confirmation.cancelFunction}
            confirmFunction={this.state.Confirmation.confirmFunction}
          />
        )}
        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
        {this.state.kriEntry.display && (
          <KRIValueEntry
            data={this.state.kriEntry.data}
            cancelFunction={this.handleKRIEntryCancel}
            submitFunction={this.handleKRIEntrySubmit}
          />
        )}
      </div>
    );
  }

  handleKRIEntry = (data) => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({ kriEntry: { display: true, data: data } });
  };
  handleKRIEntryCancel = (data) => {
    this.setState({ kriEntry: { display: false } });
  };
  handleKRIEntrySubmit = (data) => {
    data.forEach((value) => {
      if (value.id === undefined) {
        value.period = moment(value.period)
          .format()
          .replace("+08:00", "")
          .replace("T", " ");
        this.props.createKRIValue(value);
      } else {
        let id = value.id;
        delete value.id;
        this.props.updateKRIValue(value, id);
      }
    });
    //this.setState({kriEntry:{display:false}})
  };
  handleRiskDescriptionChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.description = value;
    this.setState({ newRiskDetail: newRisk });
  };
  handleAddUser = (field, index, currentUser, title) => {
    this.setState({
      AddUser: true,
      title: title,
      currentUser: { field: field, index: index, currentUser: currentUser },
    });
  };
  handleConfirmation = (title, content, confirmFunction) => {
    const confirmationFunction = () => {
      confirmFunction();
      this.setState({
        Confirmation: { display: false },
      });
    };
    const cancelFunction = () => {
      this.setState({
        Confirmation: { display: false },
      });
    };
    this.setState({
      Confirmation: {
        display: true,
        title: title,
        content: content,
        confirmFunction: confirmationFunction,
        cancelFunction: cancelFunction,
      },
    });
  };
  handleCreateTask = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({
      createTask: true,
    });
  };
  handleAddUserClose = () => {
    this.setState({ AddUser: false, currentUser: {} });
  };
  handleAddUserSubmit = (owner) => {
    let newRiskDetail = this.state.newRiskDetail;
    if (this.state.currentUser.field === "risk") {
      newRiskDetail.owner = owner;
    } else if (this.state.currentUser.field === "delegate") {
      newRiskDetail.delegate = owner;
    } else if (this.state.currentUser.field === "riskappetite") {
      newRiskDetail.riskappetite[
        this.state.currentUser.index
      ].provided_by = owner;
    } else {
      newRiskDetail[this.state.currentUser.field][
        this.state.currentUser.index
      ].owner = owner;
    }
    this.setState({ AddUser: false, currentUser: {} });
  };

  handleTabChange = (tabLabel) => {
    this.setState({ display: tabLabel });
  };

  handleRiskTierChange = (event) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.tier = parseInt(event.value.split(" ")[1]);
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskRatingChange = (type, axis, value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk[type][axis] = value;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskAppetiteChange = (newAppetite) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.riskappetite = newAppetite;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskAppetiteLevelChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.risk_appetite_level = value;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskAppetiteStatementChange = (value) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.risk_appetite_statement = value;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskDriverChange = (newDriver) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.drivers = newDriver;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskConseqChange = (newConseq) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.consequences = newConseq;
    this.setState({ newRiskDetail: newRisk });
  };

  handleRiskControlChange = (newControl) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.controls = newControl;
    this.setState({ newRiskDetail: newRisk });
  };

  handleKRIChange = (newKRI) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.KRIs = newKRI;
    this.setState({ newRiskDetail: newRisk });
  };

  handleActionPlanChange = (newActionPlan) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.actionplans = newActionPlan;
    newRisk = this.APStatusGenerated(newRisk);
    this.setState({ newRiskDetail: newRisk });
  };

  handleSubmitChange = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleUpdateRiskDetailSubmit({ data: data }, riskID);
  };

  handleApprove = () => {
    let riskID = this.state.newRiskDetail.id;
    //console.log(data)
    this.props.handleApprove(riskID);
  };
  handleSendToDelegate = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleSendToDelegate({ data: data }, riskID);
  };
  handleSendToOwner = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleSendToOwner({ data: data }, riskID);
  };
  handleSaveAsDraft = () => {
    let [data, riskID] = this.packRiskDataForSubmission();
    //console.log(data)
    this.props.handleSaveAsDraft({ data: data }, riskID);
  };
  handleCategoryChange = (category) => {
    let newRisk = this.state.newRiskDetail;
    newRisk.category = category.value;
    newRisk.category_id =
      this.props.CategoryList.indexOf(category.value, 0) + 1;
    this.setState({ newRiskDetail: newRisk });
  };
  handleITRiskTypeChange = () => {
    let newRisk = this.state.newRiskDetail;
    newRisk.it_risk = !newRisk.it_risk;
    this.setState({ newRiskDetail: newRisk });
  };
  handleNonITRiskTypeChange = () => {
    let newRisk = this.state.newRiskDetail;
    newRisk.non_it_risk = !newRisk.non_it_risk;
    this.setState({ newRiskDetail: newRisk });
  };
  taskSubmitFunction = (task) => {
    //console.log(task)
    task.task_from = this.props.currentUser.username;
    let taskPackage = { data: task };
    this.props.handleCreateTask(taskPackage);
    this.setState({ createTask: false });
  };
  handleParentRiskChange = (event) => {
    let newRisk = this.state.newRiskDetail;
    let parentRiskName = event.value.name;
    let parentID = event.value.id;
    newRisk.parentRisk_id = parentID;
    newRisk.parent_risk_name = parentRiskName;
    this.setState({ newRiskDetail: newRisk });
  };
}
const BlurEffectStyle = {
  filter: "blur(10px)",
};
export { RiskEdit };
