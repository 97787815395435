import React from "react";
import {
  mapping,
  RiskEditCardAddNew,
  RiskEditCardHeader,
  IconSVG,
  NameCard,
  DropDownListButton,
  CheckBox,
  ButtonSolid,
  NonValidationInput,
  AutoSizeTextArea,
} from "@aim-mf/styleguide";

import { RiskEditCardStyle } from "../risk-edit-common-style";

const RiskKRIsStyle = {
  paddingLeft: "5rem",
  paddingTop: "2.5rem",
};

class KRIIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(this.props);
    return (
      <div style={RiskKRIsStyle}>
        <div style={RiskEditCardStyle.mainCardStyle}>
          {/* TOP ROW HEADER - LEFT TITLE ----------------------------------------*/}
          <RiskEditCardHeader
            title={this.props.title}
            lastEditName={this.props.lastEdit}
            handleCreateTask={this.props.handleCreateTask}
          />

          <div style={{ marginTop: "3rem" }}>
            {this.props.KRIs.length !== 0 &&
              this.props.KRIs.map((kri, index) => {
                if (this.props.rights.edit === 1) {
                  return (
                    <div key={kri.id} style={{ margin: "1.25rem" }}>
                      <KRICard
                        kri={kri}
                        index={index}
                        handleKRIDescriptionChange={
                          this.handleKRIDescriptionChange
                        }
                        handleKRIOwnerChange={this.handleKRIOwnerChange}
                        handleKRIFrequencyChange={this.handleKRIFrequencyChange}
                        handleKRITypeChange={this.handleKRITypeChange}
                        handleKRILowerThresholdChange={
                          this.handleKRILowerThresholdChange
                        }
                        handleKRIUpperThresholdChange={
                          this.handleKRIUpperThresholdChange
                        }
                        handleKRIThresholdUnitChange={
                          this.handleKRIThresholdUnitChange
                        }
                        handleDelete={this.handleDelete}
                        handleKRIEntry={this.props.handleKRIEntry}
                        handleKRISafeZoneChange={this.handleKRISafeZoneChange}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={kri.id} style={{ margin: "1.25rem" }}>
                      <KRICardView kri={kri} />
                    </div>
                  );
                }
              })}
          </div>
          {this.props.rights.edit === 1 && (
            <div
              style={{
                margin: "1.25rem",
                marginTop: "2rem",
                marginBottom: "2rem",
              }}
            >
              <RiskEditCardAddNew
                buttonName={"Add new KRI"}
                clickEvent={this.handleAddNew}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  handleAddNew = () => {
    let existingKRIs = this.props.KRIs;
    let existingKRIsNumber = existingKRIs.map((KRI) => {
      return parseInt(KRI.number.slice(1));
    });
    if (existingKRIsNumber.length === 0) {
      existingKRIsNumber.push(0);
    }
    let newKRINumber = "K" + (Math.max(...existingKRIsNumber) + 1);
    let newKRI = {
      number: newKRINumber,
      description: "\u00A0",
      owner: [],
      frequency: "Annually",
      KRI_type: "Manual",
      lower_threshold: "0",
      upper_threshold: "100",
      threshold_unit: "%",
      safe_zone: "low",
    };
    existingKRIs.push(newKRI);
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIDescriptionChange = (value, index) => {
    let existingKRIs = this.props.KRIs;
    existingKRIs[index].description = value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };

  handleKRIFrequencyChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].frequency = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRITypeChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].KRI_type = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };

  // TODO: need to finish data update on threshold later
  handleKRISafeZoneChange = (index) => {
    let existingKRIs = this.props.KRIs;
    existingKRIs[index].safe_zone =
      existingKRIs[index].safe_zone === "low" ? "high" : "low";
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRILowerThresholdChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].lower_threshold = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIUpperThresholdChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].upper_threshold = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIThresholdUnitChange = (event) => {
    let existingKRIs = this.props.KRIs;
    let index = event.target.props.index;
    existingKRIs[index].threshold_unit = event.value;
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleDelete = (index) => {
    let existingKRIs = this.props.KRIs;
    existingKRIs.splice(index, 1);
    let newKRIs = existingKRIs;
    this.props.handleKRIChange(newKRIs);
  };
  handleKRIOwnerChange = (index) => {
    this.props.handleAddUser(
      "KRIs",
      index,
      this.props.KRIs[index].owner,
      "Assign Key Risk Indicator Owner"
    );
  };
}

const KRICard = (props) => {
  return (
    <div style={cardStyle}>
      {/*top right tool bar*/}
      <div
        style={{
          position: "absolute",
          height: "0px",
          display: "inline-flex",
          right: 0,
          margin: "0.5em",
        }}
      >
        <ButtonSolid
          height={"21px"}
          width={"100px"}
          name={"value entry"}
          clickEvent={() => {
            props.handleKRIEntry(props.kri);
          }}
        />
        <div style={{ width: "10px" }} />
        <IconSVG
          name={"delete"}
          color={mapping["Color_Basic_Mute_2"]}
          onClick={() => {
            props.handleDelete(props.index);
          }}
        />
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
          }}
        >
          <KRINumber KRINumber={props.kri.number} />
          <KRIDescription
            description={props.kri.description}
            index={props.index}
            handleKRIDescriptionChange={props.handleKRIDescriptionChange}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "96%",
            justifyContent: "space-between",
          }}
        >
          <KRIOwner
            KRIOwner={props.kri.owner}
            handleKRIOwnerChange={props.handleKRIOwnerChange}
            index={props.index}
          />
          <KRIFrequency
            handleKRIFrequencyChange={props.handleKRIFrequencyChange}
            frequency={props.kri.frequency}
            frequencyList={["Annually", "Quarterly", "Monthly", "Daily"]}
            index={props.index}
          />
          <KRIType
            handleKRITypeChange={props.handleKRITypeChange}
            type={props.kri.KRI_type}
            typeList={["Automatic", "Manual"]}
            index={props.index}
          />
          <KRIThreshold
            currentUnit={props.kri.threshold_unit}
            unitList={["%", ".00"]}
            KRILow={props.kri.lower_threshold}
            KRIHigh={props.kri.upper_threshold}
            index={props.index}
            safeZone={props.kri.safe_zone}
            handleKRILowerThresholdChange={props.handleKRILowerThresholdChange}
            handleKRIUpperThresholdChange={props.handleKRIUpperThresholdChange}
            handleKRIThresholdUnitChange={props.handleKRIThresholdUnitChange}
            handleKRISafeZoneChange={props.handleKRISafeZoneChange}
          />
        </div>
      </div>
    </div>
  );
};
const KRINumber = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>NO.</div>
      <div style={contentTextStyle}>{props.KRINumber}</div>
    </div>
  );
};
const KRIDescription = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextInput
          content={props.description}
          onCustomChange={props.handleKRIDescriptionChange}
          index={props.index}
        />
      </div>
    </div>
  );
};
const KRIOwner = (props) => {
  const OwnerChange = () => {
    props.handleKRIOwnerChange(props.index);
  };
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>KRI owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.KRIOwner.length !== 0 &&
          props.KRIOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
      <ButtonSolid
        name={"Add\u00a0\u00a0+"}
        fontStyle={mapping["Font_Button_Badge_White"]}
        height={"18px"}
        width={"52px"}
        size={"Small"}
        clickEvent={OwnerChange}
      />
    </div>
  );
};
const KRIFrequency = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.frequency}
          data={props.frequencyList}
          CustomOnChange={props.handleKRIFrequencyChange}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
const KRIType = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.type}
          data={props.typeList}
          CustomOnChange={props.handleKRITypeChange}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};
const KRIThreshold = (props) => {
  return (
    <div>
      <div style={{ position: "relative" }}>
        <div style={RiskEditCardStyle.sectionTitleStyle}>Thresholds</div>
        <div className={"div_hover"}>
          <IconSVG
            name={"flip"}
            color={mapping["Color_Basic_Primary"]}
            wrapperStyle={{ position: "absolute", top: 0, right: 0 }}
            onClick={() => {
              props.handleKRISafeZoneChange(props.index);
            }}
          />
        </div>
      </div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          index={props.index}
          value={props.currentUnit}
          data={props.unitList}
          CustomOnChange={props.handleKRIThresholdUnitChange}
          DropDownButtonStyle={riskThresholdTypeDropDownButtonStyle}
          disabled={props.disabled}
        />
        <ThresholdLowerInput
          unit={props.currentUnit}
          index={props.index}
          lower={props.KRILow}
          safeZone={props.safeZone}
          handleKRILowerThresholdChange={props.handleKRILowerThresholdChange}
        />

        <ThresholdMiddleRange
          unit={props.currentUnit}
          lower={props.KRILow}
          upper={props.KRIHigh}
        />
        <ThresholdUpperInput
          unit={props.currentUnit}
          index={props.index}
          upper={props.KRIHigh}
          safeZone={props.safeZone}
          handleKRIUpperThresholdChange={props.handleKRIUpperThresholdChange}
        />
      </div>
    </div>
  );
};
const TextInput = (props) => {
  const onChange = (value) => {
    props.onCustomChange(value, props.index);
  };
  return (
    <AutoSizeTextArea
      fieldstyle={InputFieldStyle}
      value={props.content}
      CustomizedOnChange={onChange}
    />
  );
};
const ThresholdLowerInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "low"
            ? ThresholdSafeInputFieldStyle
            : ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        onChange={props.handleKRILowerThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};
const ThresholdMiddleRange = (props) => {
  return (
    <div style={thresholdMiddleStyle}>
      {props.lower}
      {props.unit === "%" ? "%" : ""}-{props.upper}
      {props.unit === "%" ? "%" : ""}
    </div>
  );
};
const ThresholdUpperInput = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "high"
            ? ThresholdSafeInputFieldStyle
            : ThresholdDangerInputFieldStyle
        }
        value={props.upper}
        onChange={props.handleKRIUpperThresholdChange}
        index={props.index}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};

const KRICardView = (props) => {
  return (
    <div style={cardStyle}>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            paddingTop: 0,
            display: "inline-flex",
          }}
        >
          <KRINumber KRINumber={props.kri.number} />
          <KRIDescriptionView description={props.kri.description} />
        </div>
      </div>
      <div>
        <div
          style={{
            padding: "1.25rem",
            paddingRight: 0,
            display: "inline-flex",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <KRIOwnerView KRIOwner={props.kri.owner} />
          <KRIFrequencyView
            frequency={props.kri.frequency}
            frequencyList={["Annually", "Quarterly", "Monthly", "Daily"]}
          />
          <KRITypeView
            type={props.kri.KRI_type}
            typeList={["Automatic", "Manual"]}
          />
          <KRIThresholdView
            currentUnit={props.kri.threshold_unit}
            unitList={["%", ".00"]}
            KRILow={props.kri.lower_threshold}
            KRIHigh={props.kri.upper_threshold}
          />
        </div>
      </div>
    </div>
  );
};
const KRIDescriptionView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>description</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <TextContent content={props.description} />
      </div>
    </div>
  );
};
const KRIOwnerView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>KRI owner</div>
      <div style={{ paddingTop: "1.25rem" }}>
        {props.KRIOwner.length !== 0 &&
          props.KRIOwner.map((owner) => {
            return (
              <NameCard
                key={owner.display_name + owner.title}
                initial={owner.display_name
                  .split(" ")
                  .map((a) => {
                    return a[0].toUpperCase();
                  })
                  .join("")}
                name={owner.display_name}
                title={owner.title}
              />
            );
          })}
      </div>
    </div>
  );
};
const KRIFrequencyView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>frequency</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.frequency}
          data={props.frequencyList}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const KRITypeView = (props) => {
  return (
    <div style={{ paddingRight: "2rem" }}>
      <div style={RiskEditCardStyle.sectionTitleStyle}>type</div>
      <div style={{ paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.type}
          data={props.typeList}
          DropDownButtonStyle={riskTierDropDownButtonStyle}
          disabled={true}
        />
      </div>
    </div>
  );
};
const KRIThresholdView = (props) => {
  return (
    <div>
      <div style={RiskEditCardStyle.sectionTitleStyle}>Thresholds</div>
      <div style={{ display: "inline-flex", paddingTop: "1.25rem" }}>
        <DropDownListButton
          value={props.currentUnit}
          data={props.unitList}
          DropDownButtonStyle={riskThresholdTypeDropDownButtonStyle}
          disabled={true}
        />
        <ThresholdLowerInputView
          unit={props.currentUnit}
          lower={props.KRILow}
        />

        <ThresholdMiddleRange
          unit={props.currentUnit}
          lower={props.KRILow}
          upper={props.KRIHigh}
        />
        <ThresholdUpperInputView
          unit={props.currentUnit}
          upper={props.KRIHigh}
        />
      </div>
    </div>
  );
};

const ThresholdLowerInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "low"
            ? ThresholdSafeInputFieldStyle
            : ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        disabled={true}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};
const ThresholdUpperInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "high"
            ? ThresholdSafeInputFieldStyle
            : ThresholdDangerInputFieldStyle
        }
        value={props.upper}
        disabled={true}
      />
      {props.unit === "%" && <span style={ThresholdUnitStyle}> {"%"} </span>}
    </div>
  );
};

const TextContent = (props) => {
  return (
    <div style={TextWrapperStyle}>
      <div style={TextStyle}>
        {props.content === "" ? "\u00A0" : props.content}
      </div>
    </div>
  );
};
const TextWrapperStyle = {
  padding: "0.25rem",
  height: "fit-content",
  width: "58.5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "1px solid rgba(151,151,151,0)",
};

const TextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  padding: "0.25rem",
};

const ThresholdDangerInputFieldStyle = {
  height: "2rem",
  width: "5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  fontSize: "12px",
  padding: "4px",
  textAlign: "center",
  color: mapping["Color_Basic_Light"],
  border: "2px solid #FF0000",
  borderRadius: "4px",
  boxShadow: "none",
};
const ThresholdSymbolStyle = {
  position: "absolute",
  color: mapping["Color_Basic_Light"],
  fontSize: "12px",
  zIndex: 1,
  top: "6px",
  left: "6px",
};
const ThresholdUnitStyle = {
  position: "absolute",
  color: mapping["Color_Basic_Light"],
  fontSize: "12px",
  zIndex: 1,
  top: "7px",
  right: "6px",
};
const thresholdMiddleStyle = {
  height: "2rem",
  width: "8rem",
  backgroundColor: mapping["Color_KRI_Middle_Background"],
  fontSize: "12px",
  padding: "4px",
  paddingTop: "6px",
  textAlign: "center",
  color: "black",
};
const ThresholdSafeInputFieldStyle = {
  height: "2rem",
  width: "5rem",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  fontSize: "12px",
  padding: "4px",
  textAlign: "center",
  color: mapping["Color_Basic_Light"],
  border: "2px solid #3CA785",
  borderRadius: "4px",
  boxShadow: "none",
};

const contentTextStyle = {
  ...mapping["forms/input-default"],
  color: "white",
  paddingTop: "1.7rem",
  paddingBottom: "0.5rem",
};

const cardStyle = {
  position: "relative",
  width: "65.875rem",
  height: "fit-content",
  background: mapping["Color_BG_02_solid"],
  boxShadow: "2px 2px 10px rgb(41,50,61)", // X axis, Y axis ,blur, colour
};
const riskTierDropDownButtonStyle = {
  width: "7rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const riskThresholdTypeDropDownButtonStyle = {
  width: "3.5rem",
  height: "2rem",
  backgroundColor: mapping["Color_Basic_Secondary"],
  border: "none",
  boxShadow: "none",
};
const CheckBoxLabelStyle = {
  ...mapping["forms/label/1-default"],
  color: "white",
  padding: "0.3rem",
  paddingLeft: "0.8rem",
  paddingRight: "1.5rem",
};

const InputFieldStyle = {
  height: mapping["Field_Height"],
  width: "58.5rem",
  borderWidth: "0px",
  backgroundColor: mapping["Color_Form_00_Field_Solid"],
  border: "none !important",
  color: mapping["Color_Basic_Light"],
  padding: "4px",
  resize: "none",
  overflow: "hidden",
  //outline:mapping['Field_Outline']+' solid '+borderColor,
  paddingLeft: mapping["Field_padding_Horizontal"],
  paddingRight: mapping["Field_padding_Horizontal"],
  borderRadius: 0,
  outline: "none",
};
export { KRIIndicator };
