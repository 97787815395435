import React from "react";
import { mapping, IconSVG } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

const SideBarStyle = {
  width: "15.6875rem",
  backgroundColor: "#131821",
};

const titleCategoryFont = {
  height: "24px",
  width: "203px",
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontSize: "18px",
  fontWeight: 500,
  letterSpacing: "0.3px",
  lineHeight: "24px",
  marginTop: "2.56rem",
  marginBottom: "2.15rem",
  marginLeft: "1.125rem",
};

const subMenuFont = {
  ...mapping["paragraph/small/lightleft"],
  height: "2.625rem",
  display: "flex",
  alignItems: "center",
  marginLeft: "1.4rem",
};
const parentMenuFont = {
  height: "2.625rem",
  color: "#FFFFFF",
  fontFamily: "Roboto",
  fontSize: "13px",
  fontweight: "bold",
  letterSpacing: 0,
  lineHeight: "20px",
  display: "flex",
  alignItems: "center",
  marginLeft: "1rem",
};

const MenuWrapper = {
  height: "2.625rem",
  backgroundColor: "#131821",
  borderBottom: "1px solid #232C3D",
};
const MenuWrapperHover = {
  height: "2.625rem",
  backgroundColor: "#232C3D",
  borderBottom: "1px solid #232C3D",
};
const indicatorBar = {
  position: "absolute",
  width: "6px",
  height: "42px",
  backgroundColor: mapping["Color_Basic_Primary"],
};
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverID: 1,
      riskList: this.props.riskList,
    };
  }

  render() {
    if (!this.state.riskList.parentRisk) {
      return <div />;
    }
    return (
      <div name={"SideBar"} style={SideBarStyle}>
        <div name={"sidebar-title"} style={titleCategoryFont}>
          {this.state.riskList.parentRisk.category.toUpperCase()}
        </div>
        <ul name={"sidebar-menu"}>
          <li
            style={
              this.state.riskList.parentRisk.id === this.state.hoverID ||
              this.state.riskList.parentRisk.id === this.props.focusedID
                ? MenuWrapperHover
                : MenuWrapper
            }
            onMouseEnter={() => {
              this.setState({ hoverID: this.state.riskList.parentRisk.id });
            }}
            onMouseLeave={() => {
              this.setState({ hoverID: -1 });
            }}
          >
            {this.props.focusedID ===
              this.state.riskList.parentRisk.sourceRiskID && (
              <div style={indicatorBar} />
            )}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              style={parentMenuFont}
              onClick={() => {
                this.props.handleUpdateWithNewRiskDetailToLatest(
                  this.state.riskList.parentRisk.id
                );
              }}
            >
              <IconSVG name={"work"} color={"white"} size={"0.6"} />
              {this.state.riskList.parentRisk.name}
            </div>
          </li>
          {this.state.riskList.subRisk.map((risk) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={risk.id}
                style={
                  risk.id === this.state.hoverID ||
                  risk.id === this.props.focusedID
                    ? MenuWrapperHover
                    : MenuWrapper
                }
                onMouseEnter={() => {
                  this.setState({ hoverID: risk.id });
                }}
                onMouseLeave={() => {
                  this.setState({ hoverID: -1 });
                }}
                onClick={() => {
                  this.props.handleUpdateWithNewRiskDetailToLatest(risk.id);
                }}
              >
                {this.props.focusedID == risk.sourceRiskID && (
                  <div style={indicatorBar} />
                )}
                <div style={subMenuFont}>
                  &#11172;&nbsp;&nbsp;&nbsp;{risk.name}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export { SideBar };
