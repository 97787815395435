import { mapping } from "@aim-mf/styleguide";
const RiskEditCardStyle = {
  sectionTitleStyle: {
    ...mapping["Sub Text Tag/H7-blue-tag"],
    color: "#00BEFF",
  },
  mainCardStyle: {
    height: "fit-content",
    overflow: "auto",
    width: "1101px",
    backgroundColor: mapping["Color_BG_04_solid"],
    borderRadius: "10px",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.3)", // X axis, Y axis ,blur, colour
  },
};

export { RiskEditCardStyle };
