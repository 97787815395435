import React, { useCallback } from "react";
import { RiskEdit } from "./page/riskEdit";
import {
  fetchAPIWithPermission,
  fetchAdminAPIWithPermission,
  fetchTaskAPIWithPermission,
} from "@aim-mf/api";
import {
  SortByProperty,
  RoleSwitchPanel,
  AuthCheckAndAutofix,
} from "@aim-mf/styleguide";
import "./app.scss";
import { navigateToUrl } from "single-spa";

const initialState = {
  loadingRisk: true,
  loadingUser: true,
  loadingFullRisk: true,
  loadingParentRiskTier: true,
  searching: false,
  riskList: [],
  fullRiskList: [],
  riskDetail: {},
  userList: [],
  currentUser: {},
  searchSubscription: {},
  controlsList: [],
  controlSearchIndex: -1,
  controlAutoPopulateDetail: {},
  parentRiskTier: 1,
};

const CategoryList = [
  "Strategic",
  "Operational",
  "Financial",
  "Technology",
  "Compliance",
];

function App(props) {
  //let FocusedRiskID = props.FocusedRiskID;
  let FocusedRiskID = document.location.pathname.split("/");
  FocusedRiskID = parseInt(FocusedRiskID[FocusedRiskID.length - 2]);

  const [state, dispatch] = React.useReducer(reducer, initialState);

  // TODO: testing state need to remove later
  const {
    loadingRisk,
    loadingUser,
    loadingFullRisk,
    loadingParentRiskTier,
    riskList,
    riskDetail,
    userList,
    fullRiskList,
    currentUser,
    searchSubscription,
    searching,
    controlsList,
    controlSearchIndex,
    controlAutoPopulateDetail,
    parentRiskTier,
  } = state;

  const reloadAll = (id) => {
    navigateToUrl("/risk-edit/" + id + "/");
    FocusedRiskID = id;
    dispatch({ type: "loadingRisk" });
    dispatch({ type: "loadingFullRisk" });
    dispatch({ type: "loadingParentRiskTier" });
  };

  const handleUpdateRiskDetailSubmit = React.useCallback((data, riskID) => {
    // eslint-disable-next-line no-console
    console.log(["risks/" + riskID, data]);
    const subscription = fetchAPIWithPermission(
      "risks/" + riskID,
      data
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingRisk" });
        reloadAll(results.data.id);
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleUpdateRiskDetailSubmit(data, riskID);
        });
      }
    );

    return () => subscription.unsubscribe();
  });

  const handleControlDetailRequest = React.useCallback((controlID) => {
    //console.log("in app data get risk detail");
    //console.log(riskID);
    dispatch({ type: "populateControlDetail", controlID });
  });
  const handleSearchControls = React.useCallback((searchContent, index) => {
    dispatch({ type: "searching" });
    // dispose unwanted subscription
    if (Object.keys(searchSubscription).length !== 0) {
      searchSubscription.unsubscribe();
    }
    let requestURL =
      "controlsearch?search_str=" + encodeURIComponent(searchContent);
    //console.log(requestURL);
    let param = { data: { isArchived: "NON-ARCHIVED" } };

    const subscription = fetchAPIWithPermission(requestURL, param).subscribe(
      (results) => {
        dispatch({ type: "loadControls", results, index });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleSearchControls(searchContent, index);
        });
      }
    );

    dispatch({ type: "storeSearchSubscription", subscription });

    return () => subscription.unsubscribe();
  });

  const handleUpdateWithNewRiskDetail = (newFocusedRiskID) => {
    reloadAll(newFocusedRiskID);
  };

  let handleUpdateWithNewRiskDetailToLatest;
  handleUpdateWithNewRiskDetailToLatest = (newFocusedRiskID) => {
    const subscription = fetchAPIWithPermission(
      "getsubrisks/" + newFocusedRiskID
    ).subscribe(
      (results) => {
        let latestRiskID = SortByProperty(
          results.data.versions,
          "createdDatetime",
          "desc"
        )[0].id;
        if (results.data.id !== latestRiskID) {
          handleUpdateWithNewRiskDetailToLatest(latestRiskID);
        }
        reloadAll(newFocusedRiskID);
      },
      (err) => {
                console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleUpdateWithNewRiskDetailToLatest(newFocusedRiskID);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  React.useEffect(() => {
    dispatch({
      type: "loadCurrentUserInfo",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });
    if (loadingUser) {
      dispatch({ type: "loadingUser" });
      const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadUser", results });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadingUserFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingFullRisk) {
      dispatch({ type: "loadingFullRisk" });
      const fullRiskSubscription = fetchAPIWithPermission("getrisks", {
        data: { isArchived: "NON-ARCHIVED" },
      }).subscribe(
        (results) => {
          dispatch({ type: "loadRisk", results });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadingFullRiskFunction();
          });
        }
      );

      return () => fullRiskSubscription.unsubscribe();
    }
    if (loadingRisk) {
      dispatch({ type: "loadingRisk" });

      const subscription = fetchAPIWithPermission(
        "getsubrisks/" + FocusedRiskID
      ).subscribe(
        (results) => {
          dispatch({ type: "newRisk", results });
        },
        (err) => {
          console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadingRiskFunction();
          });
        }
      );

      return () => subscription.unsubscribe();
    }
    if (loadingParentRiskTier && !riskDetail.isParentRisk) {
      dispatch({ type: "loadingParentRiskTier" });
      const parentRiskTierSubscription = fetchAPIWithPermission(
        "getsubrisks/" + riskDetail.parentRisk_id
      ).subscribe(
        (results) => {
          dispatch({ type: "getParentRiskTier", results });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadingParentRiskTierFunction();
          });
        }
      );

      return () => parentRiskTierSubscription.unsubscribe();
    }
  }, [
    loadingUser,
    loadingRisk,
    loadingFullRisk,
    loadingParentRiskTier,
    FocusedRiskID,
    riskDetail.isParentRisk,
    riskDetail.parentRisk_id,
    loadingUserFunction,
    loadingFullRiskFunction,
    loadingRiskFunction,
    loadingParentRiskTierFunction,
  ]);

  const loadingUserFunction = useCallback(() => {
    const userSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadUser", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingUserFunction();
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });
  const loadingFullRiskFunction = useCallback(() => {
    const fullRiskSubscription = fetchAPIWithPermission("getrisks/", {
      data: { isArchived: "NON-ARCHIVED" },
    }).subscribe(
      (results) => {
        dispatch({ type: "loadRisk", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingFullRiskFunction();
        });
      }
    );

    return () => fullRiskSubscription.unsubscribe();
  });
  const loadingRiskFunction = useCallback(() => {
    const subscription = fetchAPIWithPermission(
      "getsubrisks/" + FocusedRiskID
    ).subscribe(
      (results) => {
        dispatch({ type: "newRisk", results });
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingRiskFunction();
        });
      }
    );

    return () => subscription.unsubscribe();
  });
  const loadingParentRiskTierFunction = useCallback(() => {
    const parentRiskTierSubscription = fetchAPIWithPermission(
      "getsubrisks/" + riskDetail.parentRisk_id
    ).subscribe(
      (results) => {
        dispatch({ type: "getParentRiskTier", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingParentRiskTierFunction();
        });
      }
    );

    return () => parentRiskTierSubscription.unsubscribe();
  });

  const handleSendToDelegate = (riskData, riskID) => {
    const subscription = fetchAPIWithPermission(
      "/riskdelegate/" + riskID,
      riskData
    ).subscribe(
      (results) => {
        if (results.status === 200) {
          handleUpdateWithNewRiskDetailToLatest(riskID);
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleSendToDelegate(riskData, riskID);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleSendToOwner = (riskData, riskID) => {
    const subscription = fetchAPIWithPermission(
      "/riskowner/" + riskID,
      riskData
    ).subscribe(
      (results) => {
        if (results.status === 200) {
          handleUpdateWithNewRiskDetailToLatest(riskID);
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleSendToOwner(riskData, riskID);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleApprove = (riskID) => {
    const subscription = fetchAPIWithPermission(
      "/approverisk/" + riskID
    ).subscribe(
      (results) => {
        if (results.status === 200) {
          handleUpdateWithNewRiskDetailToLatest(riskID);
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleApprove(riskID);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleSaveAsDraft = (riskData, riskID) => {
    const subscription = fetchAPIWithPermission(
      "/risk/" + riskID + "/draft",
      riskData
    ).subscribe(
      (results) => {
        if (results.status === 200) {
          handleUpdateWithNewRiskDetailToLatest(riskID);
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          handleSaveAsDraft(riskData, riskID);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const createKRIValue = (data) => {
    const subscription = fetchAPIWithPermission("/kridata", {
      data: data,
    }).subscribe(
      (results) => {
        if (results.status === 200) {
          handleUpdateWithNewRiskDetail(FocusedRiskID);
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          createKRIValue(data);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const updateKRIValue = (data, id) => {
    const subscription = fetchAPIWithPermission("/kridata/" + id, {
      data: data,
    }).subscribe(
      (results) => {
        if (results.status === 200) {
          handleUpdateWithNewRiskDetail(FocusedRiskID);
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          updateKRIValue(data, id);
        });
      }
    );

    return () => subscription.unsubscribe();
  };

  const handleCreateTask = React.useCallback((taskinfo) => {
    //console.log(taskinfo);
    const userSubscription = fetchTaskAPIWithPermission(
      "tasks/",
      taskinfo
    ).subscribe(
      (results) => {
        // once created, need to reload tasks
        //console.log(results);
        let taskID = results.data.id;
        if (taskinfo.data.attachments.length !== 0) {
          handleTaskAttachmentUpload(taskID, taskinfo.data.attachments);
        }
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleCreateTask(taskinfo);
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleTaskAttachmentUpload = (taskID, files) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file.attachment_file);
    });
    const TaskUploadSubscription = fetchTaskAPIWithPermission(
      "uploadattachment/task/" + taskID,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("upload finished");
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskAttachmentUpload(taskID, files);
        });
      }
    );
    return () => TaskUploadSubscription.unsubscribe();
  };

  return (
    <div>
      {/*{riskDetail.access_right && (*/}
      {/*  <RoleSwitchPanel*/}
      {/*    display_name={currentUser.display_name}*/}
      {/*    title={currentUser.title}*/}
      {/*    rights={riskDetail.access_right}*/}
      {/*  />*/}
      {/*)}*/}
      <RiskEdit
        loading={
          loadingFullRisk ||
          loadingUser ||
          loadingRisk ||
          (loadingParentRiskTier && !riskDetail.isParentRisk)
        }
        currentUser={currentUser}
        userList={userList}
        riskList={riskList}
        riskDetail={riskDetail}
        fullRiskList={fullRiskList}
        parentRiskTier={parentRiskTier}
        handleUpdateRiskDetailSubmit={handleUpdateRiskDetailSubmit}
        handleUpdateWithNewRiskDetail={handleUpdateWithNewRiskDetail}
        handleUpdateWithNewRiskDetailToLatest={
          handleUpdateWithNewRiskDetailToLatest
        }
        handleApprove={handleApprove}
        handleSendToDelegate={handleSendToDelegate}
        handleSendToOwner={handleSendToOwner}
        handleSaveAsDraft={handleSaveAsDraft}
        createKRIValue={createKRIValue}
        updateKRIValue={updateKRIValue}
        CategoryList={CategoryList}
        searching={searching}
        handleSearchControls={handleSearchControls}
        controlsList={controlsList}
        controlAutoPopulateDetail={controlAutoPopulateDetail}
        handleControlDetailRequest={handleControlDetailRequest}
        controlSearchIndex={controlSearchIndex}
        handleCreateTask={handleCreateTask}
      />
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loadingRisk":
        // console.log("in loading riskList")
        return { ...state, loadingRisk: true };
      case "loadingUser":
        // console.log("in loading riskList")
        return { ...state, loadingUser: true };
      case "loadingFullRisk":
        // console.log("in loading riskList")
        return { ...state, loadingFullRisk: true };
      case "loadingParentRiskTier":
        // console.log("in loading riskList")
        return { ...state, loadingParentRiskTier: true };

      case "notLoadingRisk":
        // console.log("in loading riskList")
        return { ...state, loadingRisk: false };
      case "notLoadingUser":
        // console.log("in loading riskList")
        return { ...state, loadingUser: false };
      case "notLoadingFullRisk":
        // console.log("in loading riskList")
        return { ...state, loadingFullRisk: false };
      case "notLoadingParentRiskTier":
        // console.log("in loading riskList")
        return { ...state, loadingParentRiskTier: false };

      case "newRisk":
        // console.log("in new risk state")
        let risk = action.results.data;
        let subrisk = risk.sub_risks;
        return {
          ...state,
          riskList: { parentRisk: risk, subRisk: subrisk },
          riskDetail: risk,
          loadingRisk: false,
        };
      case "loadRisk":
        return {
          ...state,
          fullRiskList: action.results.data,
          loadingFullRisk: false,
        };
      case "loadUser":
        return {
          ...state,
          userList: action.results.data,
          loadingUser: false,
        };
      case "fetchMore":
        return {
          ...state,
          loadingRisk: true,
          pageNum: state.pageNum + 1,
        };
      case "loadCurrentUserInfo":
        return {
          ...state,
          currentUser: action.data,
        };
      case "storeSearchSubscription":
        return {
          ...state,
          searchSubscription: action.subscription,
        };
      case "loadControls":
        let tailingSpaceControls = action.results.data;
        tailingSpaceControls.forEach((control) => {
          control.description = control.description + "\u00A0";
        });
        return {
          ...state,
          //riskList: state.riskList.concat(action.results.data),
          controlsList: tailingSpaceControls,
          controlSearchIndex: action.index,
          //nextPage: false,
          loadingRisk: false,
          searching: false,
        };
      case "searching":
        return {
          ...state,
          searching: true,
        };
      case "populateControlDetail":
        return {
          ...state,
          //riskList: state.riskList.concat(action.results.data),
          controlAutoPopulateDetail: state.controlsList.filter((control) => {
            return control.id === action.controlID;
          })[0],
          //nextPage: false,
          loadingRisk: false,
        };
      case "getParentRiskTier":
        return {
          ...state,
          parentRiskTier: action.results.data.tier,
          loadingParentRiskTier: false,
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
